"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefFlattener = void 0;
var Collection_1 = require("../../../Collection");
var Ref_1 = require("../../../Ref");
var RefObject_1 = require("../../../RefObject");
var RefFlattener = /** @class */ (function () {
    function RefFlattener() {
    }
    RefFlattener.Flat = function (obj) {
        if (obj instanceof Collection_1.Collection) {
            return this.FlatCollection(obj);
        }
        else if (obj instanceof Array) {
            return this.FlatArray(obj);
        }
        else {
            return this.FlatObject(obj);
        }
    };
    RefFlattener.FlatArray = function (array) {
        var _this = this;
        var flat = [];
        if (array instanceof Array) {
            flat = array.map(function (i) { return _this.FlatObject(i); });
        }
        return flat;
    };
    RefFlattener.FlatCollection = function (collection) {
        var _this = this;
        var flat = [];
        if (collection instanceof Collection_1.Collection) {
            flat = collection.Items.map(function (i) { return _this.FlatObject(i); });
        }
        return flat;
    };
    RefFlattener.IsDateValid = function (date) {
        return !isNaN(date.getTime());
    };
    RefFlattener.FlatObject = function (obj) {
        var _this = this;
        if (obj instanceof Collection_1.Collection) {
            return this.FlatCollection(obj);
        }
        else if (obj instanceof Array) {
            return this.FlatArray(obj);
        }
        else if (obj instanceof Object) {
            var flat_1 = {};
            obj.Keys().forEach(function (k) {
                var v = obj[k];
                if (v instanceof Ref_1.Ref && v.value instanceof Date) {
                    if (!_this.IsDateValid(v.value)) {
                        throw new Error("Date \"".concat(v.value, "\" is invalid"));
                    }
                    flat_1[k] = v.value.toISOString();
                }
                else if (v instanceof Date) {
                    if (!_this.IsDateValid(v)) {
                        throw new Error("Date \"".concat(v, "\" is invalid"));
                    }
                    flat_1[k] = v.toISOString();
                }
                else if (v instanceof Ref_1.Ref) {
                    flat_1[k] = v.value;
                }
                else if (v instanceof Collection_1.Collection) {
                    flat_1[k] = v.Items.map(function (x) { return _this.FlatObject(x); });
                }
                else if (v instanceof RefObject_1.RefObject) {
                    flat_1[k] = v.object;
                }
                else if (v instanceof Object) {
                    flat_1[k] = _this.FlatObject(v);
                }
                else {
                    flat_1[k] = v;
                }
            });
            return flat_1;
        }
        else {
            return obj;
        }
    };
    return RefFlattener;
}());
exports.RefFlattener = RefFlattener;
