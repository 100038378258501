import { Card } from "../../../Components/Product/Card";
import { CardInfo } from "../../../Components/Product/CardInfo";
import { Product } from "../../../Models/Product/Product";


export class ProductBox extends Card
{
    constructor(product: Product)
    {
        super(new CardInfo(product));

        this.OnClick(() => window.location.hash = `product/${product.Url.value}`);
    }
}
