
export class TicketerResult
{
    public IsSuccess: boolean = false;
    public TicketUrl: string = "";
    public ErrorMessage: string = "";

    constructor(isSuccess: boolean, ticketUrl: string, errorMessage?: string)
    {
        this.IsSuccess = isSuccess;
        this.TicketUrl = ticketUrl;
        this.ErrorMessage = errorMessage || "";
    }
}
