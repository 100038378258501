import { Collection, Ref, RefBool, RefNumber, RefString } from "@tblabs/truffle";
import { RentPrice } from "./RentPrice";
import { Opinion } from "../Description/Opinion/Opinion";
import { DescriptionFieldConverter } from "../../Services/Content/DescriptionFieldConverter";
import { RawProduct } from "./RawProduct";
import { Field } from "../Description/Field";
import { GalleryItem } from "../Description/Gallery/GalleryItem";
import { QuantityDiscount } from "./QuantityDiscount";
import { ProductCategory } from "./ProductCategory";
import { BasketItemType } from "../Basket/BasketItemType";
import { ProductOption } from "../Description/Opinion/ProductOption";
import { ProductOptions } from "./ProductOptions";


export class Product
{
    public Id: string = "";
    public Visible = new RefBool();
    public Rentable = new RefBool();
    public Sailable = new RefBool();
    public Basketable = new RefBool();
    public Options = new ProductOptions();
    public AddToBasketAs = new Ref<BasketItemType>(BasketItemType.Unset);
    public CategoryId = new RefString();
    public Url = new RefString();
    public Title = new RefString();
    public Subtitle = new RefString();
    public BasketName = new RefString();
    public Tags = new RefString();
    public RentPrices = new Collection<RentPrice>();
    public Discounts = new Collection<QuantityDiscount>();
    public SalePrice = new RefNumber()
    public Deposit = new RefNumber()
    public Features = new RefString();
    public Description = new Collection<Field>();
    public Images = new Collection<GalleryItem>();
    public Opinions = new Collection<Opinion>();
    public Extras = new Collection<Product>(); // TODO:
    public TutorialLink = new RefString();
    public Category!: ProductCategory;

    constructor(raw: Partial<RawProduct>)
    {
        if (raw.Id)
            this.Id = raw.Id;
        if (raw.Visible)
            this.Visible.value = raw.Visible;
        if (raw.Rentable)
            this.Rentable.value = raw.Rentable;
        if (raw.Sailable)
            this.Sailable.value = raw.Sailable;
        if (raw.Basketable)
            this.Basketable.value = raw.Basketable;
        if (raw.AddToBasketAs)
            this.AddToBasketAs.value = raw.AddToBasketAs as BasketItemType;
        if (raw.Options?.List)
            this.Options.List.Load(...raw.Options.List?.map(x => new ProductOption(x)));
        if (raw.CategoryId)
            this.CategoryId.value = raw.CategoryId;
        if (raw.Url)
            this.Url.value = raw.Url;
        if (raw.Title)
            this.Title.value = raw.Title;
        if (raw.Subtitle)
            this.Subtitle.value = raw.Subtitle;
        if (raw.BasketName)
            this.BasketName.value = raw.BasketName;
        if (raw.Tags)
            this.Tags.value = raw.Tags;
        if (raw.RentPrices)
            this.RentPrices.Load(...raw.RentPrices.map(x => new RentPrice(x)));
        else this.RentPrices.Load(
            new RentPrice({ Days: 3, Visible: true }),
            new RentPrice({ Days: 7, Visible: true }),
            new RentPrice({ Days: 14, Visible: true }),
            new RentPrice({ Days: 30 }),
            new RentPrice({ Days: 90 }))
        if (raw.SalePrice)
            this.SalePrice.value = raw.SalePrice;
        if (raw.Discounts)
            this.Discounts.Load(...raw.Discounts.map(x => new QuantityDiscount(x)));
        if (raw.Deposit)
            this.Deposit.value = raw.Deposit;
        if (raw.Features)
            this.Features.value = raw.Features;
        if (raw.Description)
            this.Description.Load(...raw.Description.map(x => DescriptionFieldConverter.ToClass(x)))
        if (raw.Images)
            this.Images.Load(...raw.Images.map(x => new GalleryItem(x)))
        if (raw.Opinions)
            this.Opinions.Load(...raw.Opinions.map(x => new Opinion(x)))
        if (raw.TutorialLink)
            this.TutorialLink.value = raw.TutorialLink;
    }

    public get PriceStartingFrom(): string
    {
        const visiblePrices = this.RentPrices.Items.filter(x => x.Visible.value);
        const lastVisiblePrice = visiblePrices[visiblePrices.length - 1];
        if (!lastVisiblePrice)
            return "⚠️ brak cennika";
        return Math.round(lastVisiblePrice.Value.value / lastVisiblePrice.Days.value).toFixed(0);
    }
}
