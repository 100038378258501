import { Div } from "@tblabs/truffle";


export class SearchIcon extends Div
{
    constructor()
    {
        super();

        this.Html(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-search icon search__icon">
                        <circle cx="11" cy="11" r="8"></circle>
                        <path d="m21 21-4.3-4.3"></path>
                    </svg>`).Class("search__icon");
    }
}
