import { Collection, CollectionPrinter, Div, RefString, TextInput } from "@tblabs/truffle";
import { AdminEditButton } from "../../Components/Admin/AdminEditButton";
import { AboutUs, ContactBox, FaqBox, Hero } from "../Components/Features";
import { Loader } from "../../Components/Utils/Loader";
import { Row } from "../../Components/Utils/Row";
import { AuthService } from "../../Services/Auth/AuthService";
import { WebsiteContentProvider } from "../../Services/Content/WebsiteContentProvider";
import { ProductCategoryBox } from "../Category/Components/ProductCategoryBox";
import { Product } from "../../Models/Product/Product";
import { NavBar } from "../Components/PageLogo";
import { ProductBox } from "../Product/Components/ProductBox";
import { Center } from "../../Components/Utils/Center";
import { SearchIcon } from "../../Components/Icons/SearchIcon";
import { Basket } from "../../Services/Basket/Basket";


export class MainPage extends Div
{
    private loader1 = new Loader()
    private content = new Row()

    constructor(
        private _content: WebsiteContentProvider,
        private _auth: AuthService)
    {
        super();

        this.Append(
            new Hero(),
            _auth.IsAuthorized && new AdminEditButton('edit/category'),
            this.content,
            new AboutUs(),
            new FaqBox(),
            new ContactBox(),
            this.loader1,
        )
    }

    protected async OnAppend(): Promise<void>
    {
        const content = await this._content.Get();
        const categories = content.ProductCategories.Items;

        this.loader1.RemoveSelf()

        const searchResult = new Collection<Product>()
        // let throttleTimer;
        const search = new RefString()
            .OnChange(s =>
            {
                // clearTimeout(throttleTimer)
                // setTimeout(() =>
                // {
                const toFind = s.toLowerCase()
                const products = content.Products.Filter(x =>
                {
                    return x.Tags.value.includes(toFind) && x.Title.value.toLowerCase().includes(toFind);
                });

                searchResult.Load(...products)
                // }, 1333)
            })

        this.content.Insert(

            new Div("container").Id("categories").Append(
                new Div("search").Append(
                    new SearchIcon(),
                    new TextInput(search).Class("search__input").Placeholder("Szukaj produktu...")
                ),
                new Div("products").Append(
                    ...categories.map(category => new ProductCategoryBox(category, content)),
                )
                    .VisibleWhen(search, x => x.length == 0, "grid"),

                new Div("container").Append(
                    new Div("products").Append(
                        new CollectionPrinter(searchResult, p => new ProductBox(p))
                    ),
                ).VisibleWhen(search, x => x.length > 0, "grid"),

                new Center("Nie znaleziono produktów")
                    .VisibleWhen(search, x => x.length > 0 && searchResult.Count == 0),
            )
        )
    }
}