import { Div, RefBool, Collection, Span, Link, TextInput, Checkbox } from "@tblabs/truffle";
import { appendable } from "@tblabs/truffle/Core/appendable";
import { Field } from "../../Models/Description/Field";


export class FieldEditor extends Div
{
    constructor(private type: string, private field: Field, allFields: Collection<Field>, content: appendable[] = [])
    {
        super();
        this.Background("#f8f8f8")
            .Border(4, "#333")
            .BorderRadius(8)
            .Padding(0)
            .MarginBottom(12)

        let expand = new RefBool(true).Storable(`${this.type}:${this.field.Header.value}`);

        this.Append(
            new Div().Padding(4).Background("#333").Color("#eee").Append(
                new Span(type).Bold().Background("#eee").Color("#222").Padding(2).BorderRadius(8).FloatLeft(),
                new Link("👀Zwiń/rozwiń").MarginLeft(12).FloatLeft()
                    .OnClick(() => expand.Toggle()),
                new TextInput(field.Header).Placeholder("Tytuł sekcji").WidthAuto().MarginLeft(12).FloatLeft(),
                new Checkbox(field.VisibleHeader, "Widoczny tytuł").MarginLeft(12).FloatLeft(),
                new TextInput(field.Style).Placeholder("Styl elementu (CSS)").MarginLeftRight(8).FontSize(12).FloatLeft().WidthPercent(80)
                    .Visible(expand),
                new Link("✖️").OnClick(() => allFields.Remove(field)).FloatRight(),
                allFields.CanSwapUp(field) && new Link("⬆️").FloatRight()
                    .OnClick(() => allFields.SwapUp(field)),
                allFields.CanSwapDown(field) && new Link("⬇️").FloatRight()
                    .OnClick(() => allFields.SwapDown(field)),
                new Div().ClearBoth(),
            ),
            new Div().WidthPercent(100).Append(
                ...content
            ).Visible(expand),
            new Div().ClearBoth(),
        )
    }
}
