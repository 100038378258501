import { NumberInput, TextInput, Button, Select, ModalWindow, Root, MultilineInput, DestroyingPatternContentSwitcher, HashRef, RefDate } from "@tblabs/truffle";
import moment from "moment";
import "moment/locale/pl";
import { CategoryPage } from "./Pages/Category/CategoryPage";
import { ProductPage } from "./Pages/Product/ProductPage";
import { AboutUsPage } from "./Pages/Contact/AboutUsPage";
import { ContactPage } from "./Pages/Contact/ContactPage";
import { BlogPage } from "./Pages/Blog/BlogPage";
import { ProductEditPage } from "./Pages/Product/ProductEditPage";
import { CategoryEditPage } from "./Pages/Category/CategoryEditPage";
import { WebsiteContentConverter } from "./Services/Content/WebsiteContentConverter";
import { WebsiteContentProvider } from "./Services/Content/WebsiteContentProvider";
import { OnlineStorage } from "./Services/Storage/OnlineStorage";
import { RawWebsiteContentRepo } from "./Services/Repo/RawWebsiteContentRepo";
import { Ticketer } from "./Services/Repo/Ticketer";
import { TicketSender } from "./Services/Repo/TicketSender";
import { AuthService } from "./Services/Auth/AuthService";
import { UserLocalStorage } from "./Services/Auth/UserLocalStorage";
import { LoginPage } from "./Services/Auth/LoginPage";
import { AuthApi } from "./Services/Auth/AuthApi";
import { Basket } from "./Services/Basket/Basket";
import { BasketLocalStorage } from "./Services/Basket/BasketLocalStorage";
import { PageEditPage } from "./Pages/Main/PageEditPage";
import { BasketPage } from "./Pages/Basket/BasketPage";
import { MainPage } from "./Pages/Main/MainPage";
import { DatesRange } from "./Models/Dates/DatesRange";
import { FormInfoToTicketConverter } from "./Components/OrderForm/FormInfoToTicketConverter";
import { NavBar } from "./Pages/Components/PageLogo";

moment?.locale('pl')

NumberInput.DefaultCssClasses = "uk-input uk-form-width-small";
TextInput.DefaultCssClasses = "uk-input";
Button.DefaultCssClasses = "uk-button uk-button-default";
Select.DefaultCssClasses = "uk-select";
ModalWindow.DefaultCssClasses = "tf-modal-window";
ModalWindow.Hook = Root.Hook;
MultilineInput.DefaultCssClasses = "uk-textarea";


(async () =>
{
    const root = new Root("body").Class("root")

    const _authApi = new AuthApi("https://authv2.specteam.pl");
    const _userStorage = new UserLocalStorage();
    const _auth = new AuthService(_authApi, _userStorage);
    _auth.TryAutologin();
    const _storage = new OnlineStorage("https://storage.specteam.pl/v3", _auth.Info?.App?.WebsiteContentStorageAccessToken);
    const _converter = new WebsiteContentConverter();
    const _repo = new RawWebsiteContentRepo(_storage);
    const _websiteContentProvider = new WebsiteContentProvider(_repo, _converter);
    
    const websiteContent = await _websiteContentProvider.Get()

    const _ticketsStorage = new OnlineStorage("https://storage.specteam.pl/v4", "SpecteamOrders:Anonymous");
    const _ticketCreator = new FormInfoToTicketConverter();
    const _ticketSender = new TicketSender(_ticketsStorage);
    const _ticketer = new Ticketer(_ticketCreator, _ticketSender);
    const _basketStorage = new BasketLocalStorage()
    const start = new RefDate(moment(new Date()).hour(12).minute(0).toDate()).Storable("rent-from")
    const end = new RefDate(moment(new Date()).hour(12).minute(0).toDate()).Storable("rent-to")
    const rentDatesRange = new DatesRange(start, end)
    const basket = new Basket(websiteContent.Products.Items, _basketStorage, rentDatesRange, websiteContent.BasketOptions)

    const hash = new HashRef()
    const content = new DestroyingPatternContentSwitcher(hash).Class("layout")
        .AddContent("product/:prod", ({ prod }) => new ProductPage(_websiteContentProvider, _auth, basket, prod))
        .AddContent("product/:prod/:option", ({ prod, option }) => new ProductPage(_websiteContentProvider, _auth, basket, prod, option))
        .AddContent("edit/product/:productUrl", ({ productUrl }) => new ProductEditPage(_websiteContentProvider, _auth, productUrl))
        .AddContent("add/product", () => new ProductEditPage(_websiteContentProvider, _auth))
        .AddContent("admin/login", () => new LoginPage(_auth))
        .AddContent("category/:categoryName", ({ categoryName }) => new CategoryPage(_websiteContentProvider, _auth, categoryName))
        .AddContent("edit/category", () => new CategoryEditPage(_websiteContentProvider, _auth))
        .AddContent("edit/page", () => new PageEditPage(_websiteContentProvider, _auth))
        .AddContent("kontakt", () => new ContactPage(_websiteContentProvider, _auth))
        .AddContent("o-nas", () => new AboutUsPage(_websiteContentProvider, _auth))
        .AddContent("blog", () => new BlogPage(_websiteContentProvider, _auth))
        .AddContent("blog/:url", ({ url }) => new BlogPage(_websiteContentProvider, _auth, url))
        .AddContent("basket", () => new BasketPage(_websiteContentProvider, basket, _ticketer))
        .AddDefaultContent(() => new MainPage(_websiteContentProvider, _auth))

    root.Append(
        new NavBar(basket),
        content,
    )

})()
