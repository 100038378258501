import { Div, Ref, Button, Text, Link, PasswordInput, Span } from "@tblabs/truffle";
import { AuthService } from "./AuthService";
import { Row } from "../../Components/Utils/Row";


export class LoginPage extends Div
{
    private password = new Ref<string>("").Storable("admin-password");
    private loginButton = new Button("Zaloguj");
    private loginStatus = new Ref<string>("");
    private statusBox = new Text(this.loginStatus).DisplayBlock()
        .Background("orange").FloatLeft().Padding(9, 32).MarginTop(8).Color("#fff")
        .Hide()

    constructor(private _auth: AuthService)
    {
        super();
        this.Margin(32)
    }

    protected async OnAppend(): Promise<void>
    {
        if (this._auth.IsAuthorized)
        {
            this.Append(
                `Jesteś zalogowany jako ${this._auth.Info?.User?.Name || "(brak nazwy użytkownika)"}`,
                this._auth.IsAuthorized && new Button("Wyloguj")
                    .OnClick(() =>
                    {
                        this._auth.Logout();
                        window.location.hash = "/";
                    })
            )

            return;
        }

        const passwordInput = new PasswordInput(this.password)
            .OnEnterKeyPressed(async () => await this.TryLogin())
            .OnKeyDown(() => this.statusBox.Hide());

        this.loginButton
            .OnClick(async () => await this.TryLogin())
            .EnableWhen(this.password, v => (v as string).length > 0);

        this.Append(
            new Row(
                new Span("Hasło").DisplayBlock().TextAlignLeft(),
                passwordInput,
                this.loginButton.Class("uk-button-primary").MarginTop(8).FloatRight(),
                this.statusBox)
        )
    }

    private async TryLogin()
    {
        if (this.password.value == "")
        {
            this.ShowStatus("No password provided");
            return;
        }

        const pass = this.password.value.trim();

        this.loginButton.Label("Trying to login...");

        const loginTimeoutTimer = setTimeout(() =>
        {
            this.ShowStatus("Authorization timeout. Try again later");
            this.loginButton.Label("Try login again");
        }, 15000);

        const isLoggedIn = await this._auth.TryLogin(pass);

        clearTimeout(loginTimeoutTimer);

        if (isLoggedIn)
        {
            window.location.hash = "/"; // main page
        }
        else
        {
            this.ShowStatus("Authorization problem");
            this.loginButton.Label("Try again");
        }
    }

    private ShowStatus(status: string): void
    {
        this.statusBox.Show();
        this.loginStatus.value = status;
    }
}
