import { RefNumber, RefString } from "@tblabs/truffle";
import { RawOpinion } from "./RawOpinion";

export class Opinion
{
    public Rate = new RefNumber();
    public Advantages = new RefString();
    public Disadvantages = new RefString();
    public Comment = new RefString();
    public Author = new RefString();

    constructor(raw: Partial<RawOpinion>)
    {
        this.Rate.value = raw.Rate || (-1);
        this.Advantages.value = raw.Advantages || "";
        this.Disadvantages.value = raw.Disadvantages || "";
        this.Comment.value = raw.Comment || "";
        this.Author.value = raw.Author || "";
    }
}
