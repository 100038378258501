import { Div, Span } from "@tblabs/truffle";
import { Table } from "@tblabs/truffle/Controls/TableBuilder/Table";
import { Discount } from "../../Models/Basket/Discount";
import { FormPaymentForm } from "../../Models/OrderTicket/PaymentForm";
import { Markdown } from "../Utils/Markdown";
import { FormInfo } from "./FormInfo";


export class CostsSummaryTable extends Div
{
    constructor(info: FormInfo)
    {
        super();

        this.Append(
            new Table([],
                [
                    ...info.costs.ListOfCosts.map(x =>
                    {
                        if (x instanceof Discount)
                            return ["● " + x.Label, new Span("– " + x.Value.toFixed(2) + " zł").Color("blue")];
                        else
                            return ["● " + x.Label, x.Value.toFixed(2) + " zł"];
                    }),
                ]
            ).Class("uk-table").WidthPercent(100).WidthAuto().MarginAuto().TextAlignLeft(),
            new Div().TextAlignLeft().PaddingLeft(16).Append(
                new Markdown(`Łącznie do wpłaty: **${info.costs.GetNonReturnableCostsSum().toFixed(2)} zł**`).DisplayInlineBlock(),
                info.basket.IsAnythingToRent.value && new Markdown(`(w tym **${info.costs.GetReturnableCostsSum().toFixed(2)} zł** kaucji zwrotnej)`)
                    .MarginLeft(8).MarginTop(24).DisplayInlineBlock(),
                info.basket.IsAnythingToRent.value && new Markdown(`Do zwrotu po oddaniu sprzętu: **${info.costs.GetReturnableCostsSum().toFixed(2)} zł**`)
                    .DisplayBlock().Margin(0),
                info.paymentForm.Is(FormPaymentForm.AllWithCrypto) && new Span(`Wybrano płatność w Bitcoin. Złotówki zostaną przeliczone na kryptowalutę dopiero w kolejnym kroku.`).MarginLeft(32).Italic()
            ),
        )
    }
}
