import { Div, RefString, Text } from "@tblabs/truffle";
import { Center } from "../../../Components/Utils/Center";
import { DangerButton } from "../../../Components/Utils/DangerButton";
import { Product } from "../../../Models/Product/Product";
import { Basket } from "../../../Services/Basket/Basket";


export class BasketableSection extends Div
{
    constructor(product: Product, basket: Basket, onClick: (product: Product) => void, option: RefString)
    {
        super();

        const GetOptionFactor = () => product.Options.GetPriceFactor(option.value) * basket.BasketOptions.GeneralPriceFactor.value;

        this.Append(
            new Text(option, _ => `${(product.SalePrice.value * GetOptionFactor()).toFixed(2)}zł brutto`).Bold().Color("#444"),
            new Text(option, _ => `≈ ${(product.SalePrice.value * GetOptionFactor() * 0.77).toFixed(2)}zł netto\n`).MarginLeft(8).Color("#999").FontItalic(),
            new Center(
                new DangerButton("Dodaj do koszyka", () => onClick(product))
            )
        );
    }
}
