import { Div, MultipleFilesInput, Snack } from "@tblabs/truffle";
import { PicturePack } from "../../../Services/PhotoStorage/PicturePack";
import { OnlineStorage } from "../../../Services/PhotoStorage/OnlineStorage";


export class GalleryLoader extends Div
{
    constructor(onload?: (images: PicturePack[]) => void)
    {
        super("entry");

        this.Append(
            "Dodaj wiele zdjęć\n",
            new MultipleFilesInput(async (files) =>
            {
                new Snack("Uploading...");
                const _storage = new OnlineStorage("https://photos.specteam.pl");
                const uploadResult = await _storage.UploadMany(files, "specteam");
                new Snack(uploadResult.IsSuccess ? `✅ Uploaded ${uploadResult.PicturesPacks.length} files` : "🔴 Upload error", uploadResult.ErrorMessage);
                if (uploadResult.IsSuccess)
                    onload?.(uploadResult.PicturesPacks);
            }),
            new Div().ClearBoth()
        )
    }
}
