import { ComponentBase } from "@tblabs/truffle";
import { Path } from "../Product/Path";

export class ArrowSvg extends ComponentBase<HTMLElement>
{
    constructor()
    {
        super("svg");
        this.Attribute("xmlns", "http://www.w3.org/2000/svg");
        this.Attribute("width", "24");
        this.Attribute("height", "24");
        this.Attribute("viewBox", "0 0 24 24");
        this.Attribute("fill", "none");
        this.Attribute("stroke", "currentColor");
        this.Attribute("stroke-width", "2");
        this.Attribute("stroke-linecap", "round");
        this.Attribute("stroke-linejoin", "round");
        this.Class("lucide lucide-arrow-right product-card__button-icon");
        this.Append(
            new Path("M5 12h14"),
            new Path("m12 5 7 7-7 7")
        );
    }
}
