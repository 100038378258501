import { Ref, RefString, RefBool } from "@tblabs/truffle";
import { DeliveryFormV1 } from "../../Models/Basket/DeliveryForm";
import { ReturnForm } from "../../Models/Basket/ReturnForm";
import { FormPaymentForm } from "../../Models/OrderTicket/PaymentForm";
import { IBasket } from "../../Services/Basket/IBasket";
import { RefWatcher } from "../../Utils/RefWatcher";
import { Costs } from "./Costs";
import { CustomerInfo } from "./CustomerInfo";


export class FormInfo
{
    public Customer = new CustomerInfo();
    public paymentForm = new Ref<FormPaymentForm>(FormPaymentForm.Unset).Storable("payment-form");
    public deliveryForm = new Ref<DeliveryFormV1>(DeliveryFormV1.Unset).Storable("delivery-form");
    public returnForm = new Ref<ReturnForm>(ReturnForm.Unset).Storable("return-form");

    public depositInfo = new RefString();
    public depositReturnInfo = new RefString();
    public trainingInfo = new RefString();
    public allowCashPayment = new RefBool();
    public showCustomerDepositAccountInput = new RefBool();
    public placeWithTraining = new RefString("Warszawa/Wola");
    public placeWithoutTraining = new RefString("Warszawa/Mokotów");

    public costs = new Costs(this.basket, this.paymentForm, this.deliveryForm);


    constructor(public basket: IBasket)
    {
        RefWatcher.Watch([this.deliveryForm, this.paymentForm, this.returnForm, basket.Items], () =>
        {
            const personalDelivery = [DeliveryFormV1.PersonalWithTraining, DeliveryFormV1.PersonalWithoutTraining].includes(this.deliveryForm.value);
            const personalReturn = [ReturnForm.Personal].includes(this.returnForm.value);

            // // 🎓 Training
            // this.trainingInfo.value = {
            //     [DeliveryFormV0.Pocztex]: `Szkolenie odbędzie się przez telefon. Otrzymasz także materiały do samodzielnej nauki`,
            //     [DeliveryFormV0.PersonalWithTraining]: `Szkolenie odbędzie się na miejscu. Otrzymasz także materiały do samodzielnej nauki`,
            //     [DeliveryFormV0.PersonalWithoutTraining]: `Szkolenie nie odbędzie się na miejscu. Zamiast tego otrzymasz materiały do samodzielnej nauki oraz dostęp do darmowej infolinii`,
            // }[this.deliveryForm.value] || "...";

            // Package + Cash is not possible, so switch to the next available option
            if (this.deliveryForm.Is(DeliveryFormV1.PackageWithoutTraining) && this.paymentForm.Is(FormPaymentForm.AllWithCash))
                this.paymentForm.value = FormPaymentForm.DepositWithBankTransfer_ServiceWithCash;
            // Nothing to rent + Personal delivery + Cash at delivery is not possible, so switch to the next available option
            // if (!basket.IsAnythingToRent && !this.deliveryForm.Is(DeliveryForm.Pocztex) && this.paymentForm.Is(FormPaymentForm.CashAtDelivery))
                // this.paymentForm.value = FormPaymentForm.AllWithCash;

            // 💳 Payment
            this.allowCashPayment.value = personalDelivery;

            // 💰 Deposit
            this.depositInfo.value = {
                [FormPaymentForm.AllWithCash]: `Kaucja zostanie pobrana gotówką podczas odbioru sprzętu`,
                [FormPaymentForm.DepositWithBankTransfer_ServiceWithCash]: `Łączna suma kaucji wynosi ${basket.GetDepositsSum()}zł. Płatne z góry przelewem (opcje płatności będą widoczne w kolejnym kroku)`,
                [FormPaymentForm.AllWithBankTransfer]: `Kwota kaucji zostanie doliczona do kwoty przelewu`,
                [FormPaymentForm.AllWithCrypto]: `Kaucja będzie płatna Bitcoinem wraz z całością zamówienia`,
            }[this.paymentForm.value] || "...";

            // 💰 Deposit return 
            this.depositReturnInfo.value = {
                [FormPaymentForm.AllWithCash]: `Kaucja zostanie zwrócona na miejscu w gotówce`,
                [FormPaymentForm.AllWithBankTransfer]: `Kaucja zostanie zwrócona na konto, z którego przyszła`,
                [FormPaymentForm.DepositWithBankTransfer_ServiceWithCash]: `Kaucja zostanie odesłana na konto, z którego przyszła`,
                [FormPaymentForm.AllWithCrypto]: `Kaucja zostanie zwrócona na wskazany portfel (wyliczona z proporcji)`,
            }[this.paymentForm.value] || "...";
      
            this.showCustomerDepositAccountInput.value = personalDelivery && !personalReturn && this.paymentForm.Is(FormPaymentForm.AllWithCash);

            this.costs.Update();
        });
    }
}
