import { Collection, CollectionPrinter, Link } from "@tblabs/truffle";
import { Field } from "../../../Models/Description/Field";
import { FieldEditor } from "../../Utils/FieldEditor";
import { GalleryField } from "../../../Models/Description/Gallery/GalleryField";
import { GalleryItemEditor } from "./GalleryItemEditor";
import { GalleryLoader } from "./GalleryLoader";
import { GalleryItem } from "../../../Models/Description/Gallery/GalleryItem";
import { AddFieldBox } from "../../Admin/AddFieldBox";


export class GalleryItemsEditor extends FieldEditor
{
    constructor(field: GalleryField, fields: Collection<Field>)
    {
        super("Galeria", field, fields, [
            new CollectionPrinter(field.Images, x => new GalleryItemEditor(x, field.Images)),
            new AddFieldBox(
                new Link("➕ Obraz").OnClick(() => field.Images.Add(new GalleryItem({}))),
                new Link("➕ Obrazy").OnClick(() => this.Append(
                    new GalleryLoader((images) =>
                        field.Images.Add(
                            ...images.map(x => new GalleryItem({ NormalizedUrl: x.Normalized, ThumbnailUrl: x.Thumbnail, MiniUrl: x.Mini }))))
                )),
            ),
        ]);
    }
}
