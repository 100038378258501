import { PaymentForm } from "../../Components/OrderForm/PaymentForm";
import { DeliveryForm } from "../Basket/DeliveryForm";
import { ReturnForm } from "../Basket/ReturnForm";
import { HumanType } from "./HumanType";
import { RawHuman } from "./RawHuman";
import { RawTicketBasketItem } from "./RawTicketBasketItem";
import { TicketMeta } from "./TicketMeta";


export class Ticket
{
    public Id = "";
    public Meta = new TicketMeta();
    public BasketList: RawTicketBasketItem[] = [];
    public Customer: RawHuman = { Type: HumanType.Customer, Name: "", Phone: "", Email: "", Address: "", Experience: -1, Description: "", BankAccountNumber: "" };
    public Delivery: DeliveryForm = DeliveryForm.Unset;
    public Return: ReturnForm = ReturnForm.Unset;
    public DepositPaymentForm: PaymentForm = PaymentForm.Unset;
    public BasketPaymentForm: PaymentForm = PaymentForm.Unset;
    public WithTraining: boolean = false;
    public PickupPlace: string = "";
}
