import { Collection } from "@tblabs/truffle";
import { ProductOption } from "../Description/Opinion/ProductOption";


export class ProductOptions
{
    public List = new Collection<ProductOption>();
    
    public GetById(OptionId: string): ProductOption
    {
        return this.List.Items.find(x => x.Url.value == OptionId) ?? new ProductOption({ Label: "Opcja domyślna", Url: "", Description: "", PriceFactor: 1 });
    }

    public GetPriceFactor(optionId: string): number
    {
        return this.List.Items.find(x => x.Url.value == optionId)?.PriceFactor?.value ?? 1;
    }
}
