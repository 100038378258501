export class RentPriceCalculator
{
    public static Calc(prices: { price: number; days: number; }[], totalDays: number): number
    {
        if (totalDays > 90)
        {
            throw new Error(`Max range is 90 days`);
        }

        let range = 0;

        if (totalDays <= prices[0].days)
        {
            return prices[0].price;
        }

        prices.unshift({ price: prices[0].price, days: 0 }); // hack to simplify logic

        for (let i = 0; i < prices.length - 1; i++)
        {
            // console.log("#", i, ":", prices[i].days, "-", prices[i + 1].days, ", price for that range:", prices[i].price, ", searching for", totalDays)
            if ((prices[i].days <= totalDays) && (totalDays <= prices[i + 1].days))
            {
                range = i;

                const rangeSize = prices[range + 1].days - prices[range].days;
                const pricePerDayInRange = (prices[range + 1].price - prices[range].price) / rangeSize;
                const dayInRange = totalDays - prices[range].days;
                // console.log('range size:', rangeSize, ', per day:', pricePerDayInRange, ', day in range:', dayInRange)
                return Math.round(prices[range].price + dayInRange * pricePerDayInRange);
            }
        }

        if (range >= prices.length - 1)
        {
            // console.log('found range index:', range);
            range = prices.length;
        }

        return prices[range].price;
    }
}

export class DiscountCalculator
{
    public static Calc(discounts: { quantity: number, discountPercent: number }[], quantity: number): { discountMultiplier: number, discountPercent: number }
    {
        // Szuka zniżki na podstawie ilości
        const applicableDiscount = discounts
            .filter(discount => quantity >= discount.quantity) // Filtrujemy zniżki, które mają ilość mniejszą lub równą zamówieniu
            .sort((a, b) => b.quantity - a.quantity) // Sortujemy zniżki malejąco wg ilości
            .shift(); // Wybieramy największą ilość, która pasuje

        // Jeśli nie ma pasującej zniżki, zwrócimy 1 (brak zniżki)
        if (!applicableDiscount) return ({ discountMultiplier: 1, discountPercent: 0 });

        // Zwróć wartość jako mnożnik, czyli 1 - (znizka / 100)
        const discountMultiplier = 1 - (applicableDiscount.discountPercent / 100);

        return ({ discountMultiplier, discountPercent: applicableDiscount.discountPercent });
    }
}
