import { Div, Paragraph, Span } from "@tblabs/truffle";
import { CardInfo } from "./CardInfo";
import { CardFigure } from "./CardFigure";
import { ArrowSvg } from "../Icons/ArrowSvg";


export class Card extends Div
{
    constructor(info: CardInfo)
    {
        super("product-card group");

        this.Append(
            new CardFigure(info.ImageUrl),
            new Div("product-card__content")
                .Append(
                    new Div("product-card__title")
                        .Id(`product-${info.Title.toLowerCase()}`)
                        .Text(info.Title),
                    new Div("product-card__description")
                        .Text(info.Description)
                ),
            new Div("product-card__footer")
                .Append(
                    new Paragraph("product-card__price")
                        .Append(
                            new Span(info.Price)
                        ),
                    new Div("product-card__button")
                        .Attribute("aria-hidden", "true")
                        .Append(
                            new Span("Zobacz więcej"),
                            // new Div().Html(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-right product-card__button-icon">
                            //                 <path d="M5 12h14"></path>
                            //                 <path d="m12 5 7 7-7 7"></path>
                            //             </svg>`)
                            new ArrowSvg(),
                        )
                )
        );
    }
}
