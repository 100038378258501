import { Div, TextInput, Ref, Validator, Text } from "@tblabs/truffle";


export class ValidableTextInput<T> extends Div
{
    private input: TextInput;

    constructor(ref: Ref<string>, validate?: (v: Validator<string>) => void)
    {
        super();

        const validator = new Validator(ref).MustBeNotEmpty();
        validate?.(validator);

        this.input = new TextInput(ref).WidthPercent(95);

        this.Append(
            this.input,
            new Text(validator.IsValid, v => v ? "✅" : "❌").MarginLeft(8)
        );
    }

    public Placeholder(text: string): this
    {
        this.input.Placeholder(text);

        return this;
    }
}
