import { Div, Link, Ref, DestroyingContentSwitcher } from "@tblabs/truffle";
import { Center } from "../Utils/Center";
import { Basket } from "../../Services/Basket/Basket";
import { Ticketer } from "../../Services/Repo/Ticketer";
import { OrderFormView } from "./OrderFormView";
import { FormInfo } from "./FormInfo";
import { FormBox } from "./FormBox";
import { SummaryBox } from "./SummaryBox";
import { CenteredLoader } from "../Utils/Loader";
import { TicketerResult } from "../../Services/Repo/TicketerResult";


export class OrderForm extends Div
{
    constructor(basket: Basket, _ticketer: Ticketer)
    {
        super();

        const view = new Ref<OrderFormView>(OrderFormView.Form)
        const info = new FormInfo(basket) 
        let ordersSystemWebsite: string = "";

        this.Append(
            new DestroyingContentSwitcher(view)
                .AddContent(OrderFormView.Form, () => new FormBox(info, view))
                .AddContent(OrderFormView.Summary, () => new SummaryBox(info, view))
                .AddContent(OrderFormView.Sending, () =>
                {
                    _ticketer.Send(info)
                        .then((result: TicketerResult) =>
                        {
                            if (result.IsSuccess)
                            {
                                ordersSystemWebsite = result.TicketUrl;
                                basket.Clear();

                                view.value = OrderFormView.Sent;
                            }
                            else
                                view.value = OrderFormView.SendingFault;
                        })
                        .catch(error =>
                        {
                            console.log(error);
                            view.value = OrderFormView.SendingFault;
                        });

                    return new CenteredLoader("Wysyłanie...");
                })
                .AddContent(OrderFormView.Sent, () =>
                {
                    setTimeout(() =>
                    {
                        window.open(ordersSystemWebsite, '_blank')?.focus();
                    }, 3 * 1000)

                    return new Center("Zgłoszenie zostało wysłane. Za chwilę nastąpi przekierowanie na stronę zamówienia...").PaddingTop(32)
                })
                .AddContent(OrderFormView.SendingFault, () =>
                {
                    return new Center("Nie udało się wysłać zgłoszenia. Spróbuj ponownie za chwile.\n\n",
                        new Link("Wyślij ponownie").OnClick(() => view.value = OrderFormView.Summary)
                    )
                }),
        )
    }
}
