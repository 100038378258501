import { RefNumber, RefString } from "@tblabs/truffle";
import { BasketItemType } from "../../Models/Basket/BasketItemType";
import { Product } from "../../Models/Product/Product";


export abstract class BasketItem
{
    public abstract Update();

    // Input:
    public Type = BasketItemType.Unset;
    public ProductId: string = "";
    public OptionId = "";
    public Quantity = new RefNumber(1);

    public readonly Product: Product;

    // Output:
    public Label = new RefString(); // Np. "Wypożyczenie na 4 dni" lub "Zakup"
    public DiscountLabel = new RefString(); // Np. "5% zniżki" lub "brak zniżki"
    public PriceLabel = new RefString(); // Np. "100zł/3dni, 200zł/5dni" lub "1000zł/szt"
    public FinalPrice = new RefNumber();
    public FinalDeposit = new RefNumber();

    constructor(product: Product)
    {
        this.Product = product;
    }
}
