
export enum FormPaymentForm
{
    Unset = "Unset",
    AllWithCash = "AllWithCash",
    AllWithBankTransfer = "AllWithBankTransfer",
    AllWithCrypto = "AllWithCrypto",
    DepositWithBankTransfer_ServiceWithCash = "DepositPrepaidServicePostpaid",
    // PartialDepositWithBankTransfer_ServiceWithCash = "PartialDepositPrepaidServicePostpaid",
    // FromPreviousOrder = "FromPreviousOrder",
    // CashAtDelivery = "CashAtDelivery"
}
