import { Div, Span, Button, RefString } from "@tblabs/truffle";
import { Center } from "../../../Components/Utils/Center";
import { Product } from "../../../Models/Product/Product";
import { RentPriceRow } from "./RentPriceRow";
import { Basket } from "../../../Services/Basket/Basket";


export class RentSection extends Div
{
    constructor(product: Product, basket: Basket, onClick: (product: Product) => void, option: RefString)
    {
        super();

        this.Append(
            ...product.RentPrices.Items.filter(x => x.Visible.value)
                .map(x => new RentPriceRow(x, option, product, basket.BasketOptions)),
            new Span(`ℹ️ Minimalny okres najmu: 3 dni`).FontSize(12),
            "\n",
            new Span(`ℹ️ Kaucja zwrotna: ${product.Deposit.value}zł`).FontSize(12),
            new Center(
                new Button("Wypożycz").Class("selected").Width(140)
                    .OnClick(() => onClick(product))
            )
        )
    }
}
