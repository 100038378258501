import { Collection, TextInput, Div, Image, MultilineInput, NumberInput, RefNumber, ComponentBase, Ref, RV } from "@tblabs/truffle";
import { EntryEdit } from "../../Utils/EntryEdit";
import { GalleryItem } from "../../../Models/Description/Gallery/GalleryItem";


export class GalleryItemEditor extends EntryEdit<GalleryItem>
{
    constructor(image: GalleryItem, images: Collection<GalleryItem>)
    {
        super(image, images);
        this.Padding(8)

        this.Append(
            new Div().WidthPercent(70).FloatLeft().Append(
                new TextInput(image.NormalizedUrl).Placeholder("Znormalizowany obraz"),
                new TextInput(image.ThumbnailUrl).Placeholder("Miniatura"),
                new TextInput(image.MiniUrl).Placeholder("Mikro"),
                "\nOffset (x/y): ", new NumberInput(image.XOffset), new NumberInput(image.YOffset),
                new MultilineInput(image.Description).Rows(2).Placeholder("Opis"),
            ),
            new Image(image.ThumbnailUrl).FloatRight().WidthPercent(28).ObjectFit("cover").ObjectPosition(image.XOffset, image.YOffset),
            new Div().ClearBoth(),
        )
    }
}
