import { Div, Text, Rate } from "@tblabs/truffle";
import { Opinion } from "../../../Models/Description/Opinion/Opinion";


export class OpinionPrinter extends Div
{
    constructor(opinion: Opinion)
    {
        super();
        this.TextAlignLeft().DisplayBlock()
            .Padding(16, 20)
            .MarginBottom(16)
            .Background("#fcfcfc").BorderRadius(8)
            .Append(
                new Rate(opinion.Rate, { Readonly: true, SelectedSymbol: "⭐", DeselectedSymbol: "⭐", DeselectedStyle: "opacity: 0.4" })
                    .DisplayInline(),
                new Text(opinion.Author).MarginLeft(12).Color("#b37504").DisplayInline(),
                new Text(opinion.Comment).DisplayBlock().Margin(8).Italic().Padding(12),
                opinion.Advantages.value && new Text(opinion.Advantages, v => `Zalety: ${v}`).Color("green").FontSize(14).DisplayBlock().Margin(4),
                opinion.Disadvantages.value && new Text(opinion.Disadvantages, v => `Wady: ${v}`).Color("red").FontSize(14).DisplayBlock().Margin(4),
            )
    }
}
