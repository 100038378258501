import { Div, Text, RefString } from "@tblabs/truffle";
import { RentPrice } from "../../../Models/Product/RentPrice";
import { Product } from "../../../Models/Product/Product";
import { BasketOptions } from "../../../Services/Basket/IBasket";


export class RentPriceRow extends Div
{
    constructor(rentPrice: RentPrice, option: RefString, product: Product, basketOptions: BasketOptions)
    {
        super();

        const getOptionFactor = () => product.Options.GetPriceFactor(option.value) * basketOptions.GeneralPriceFactor.value;

        this.Append(
            new Text(option, _ => `${(rentPrice.Value.value * getOptionFactor()).toFixed(0)}zł / ${rentPrice.Days.value} dni`)
                .Bold().Color("#444"),
            new Text(option, _ => `≈ ${((rentPrice.Value.value * getOptionFactor()) / rentPrice.Days.value).toFixed(0)}zł / dzień`)
                .MarginLeft(8).Color("#999").FontItalic()
        )
    }
} 