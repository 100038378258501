
export enum DeliveryFormV0
{
    Unset = "Unset",
    Pocztex = "Pocztex",
    Inpost = "Inpost",
    PersonalWithTraining = "PersonalWithTraining",
    PersonalWithoutTraining = "PersonalWithoutTraining",
    Rentomat = "Rentomat"
}
export enum DeliveryFormV1
{
    Unset = "Unset",
    PackageWithoutTraining = "Package",
    PersonalWithTraining = "PersonalWithTraining",
    PersonalWithoutTraining = "PersonalWithoutTraining",
    PackageWithTraining = "PackageWithTraining",
}

export const DeliveryFormLabelToValue = {
    "Kurier Pocztex pobranie (1zł)": DeliveryFormV0.Pocztex,
    "Odbiór osobisty bez szkolenia (+1zł)": DeliveryFormV0.PersonalWithoutTraining,
    "Odbiór osobisty ze szkoleniem (+51zł)": DeliveryFormV0.PersonalWithTraining,
    "Paczkomat Inpost (1zł)": DeliveryFormV0.Inpost,
    "Rentomat": DeliveryFormV0.Rentomat,
}

export const DeliveryFormValueToLabel = {
    [DeliveryFormV0.Pocztex]: "Kurier Pocztex pobranie",
    [DeliveryFormV0.PersonalWithoutTraining]: "Odbiór osobisty bez szkolenia",
    [DeliveryFormV0.PersonalWithTraining]: "Odbiór osobisty ze szkoleniem",
    [DeliveryFormV0.Inpost]: "Paczkomat Inpost",
    [DeliveryFormV0.Rentomat]: "Rentomat",
}
export const DeliveryFormV1ValueToLabel = {
    [DeliveryFormV1.PackageWithTraining]: "Paczka (kurier Pocztex)",
    [DeliveryFormV1.PackageWithoutTraining]: "Paczka (kurier Pocztex)",
    [DeliveryFormV1.PersonalWithTraining]: "Odbiór osobisty ze szkoleniem",
    [DeliveryFormV1.PersonalWithoutTraining]: "Odbiór osobisty bez szkolenia",
}

export enum DeliveryForm
{
    Unset = "Unset",
    Personal = "Personal",
    Package = "Package"
}
