import { Button, Checkbox, Div, Image, Link, RefBool } from "@tblabs/truffle";
import { MiniBasketBox } from "../../Components/MiniBasket/MiniBasketBox";
import { Basket } from "../../Services/Basket/Basket";
import { appendable } from "@tblabs/truffle/Core/appendable";

class Box extends Div
{
    constructor(className: string, content: appendable[])
    {
        super(className);
        this.Append(...content);
    }
}
class Svg extends Div
{
    constructor(asText: string)
    {
        super();
        this.Html(asText);
    }
}
export class NavBar extends Div
{
    constructor(basket: Basket)
    {
        super("navbar navbar--fixed");

        const showMiniBasket = new RefBool();
        const showMenu = new RefBool();

        this.Append(
            new Box("container", [
                new Box("navbar__content", [
                    new Box("navbar__logo", [
                        new Image("https://www.specteam.pl/img/logo_white.png") // TODO: zmienić na photos.specteam.pl
                            .FloatLeft()
                            .CursorPointer()
                            .OnClick(() => window.location.hash = "/"),
                    ]),
                    new Box("navbar__menu", [
                        new Box("navbar__links", [
                            new Link("Produkty").Class("navbar__link"),
                            new Link("Wypożyczalnia").Class("navbar__link"),
                            new Link("O nas").Class("navbar__link"),
                            new Link("Kontakt").Class("navbar__link"),
                        ]),
                    ]),
                    new Box("navbar__actions", [
                        new Svg(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-search nav-icon"><circle cx="11" cy="11" r="8"></circle><path d="m21 21-4.3-4.3"></path></svg>`),
                        new Svg(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shopping-cart nav-icon"><circle cx="8" cy="21" r="1"></circle><circle cx="19" cy="21" r="1"></circle><path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"></path></svg>`)
                            .OnClick(() => showMiniBasket.Toggle()),
                        new MiniBasketBox(basket, showMiniBasket),
                    ]),

                    new Button("").Class("navbar__mobile-button md:hidden").Style(`opacity: 1; transform: none;`).Append(
                        new Svg(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-menu "><line x1="4" x2="20" y1="12" y2="12"></line><line x1="4" x2="20" y1="6" y2="6"></line><line x1="4" x2="20" y1="18" y2="18"></line></svg>`),
                    ).OnClick(() => showMenu.True()).VisibleWhen(showMenu, v => !v),
                ]),

                new Button("").Class("navbar__mobile-close").Style(`opacity: 1; transform: none;`).Append(
                    new Svg(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x navbar__mobile-close-icon"><path d="M18 6 6 18"></path><path d="m6 6 12 12"></path></svg>`),
                ).Visible(showMenu).OnClick(() => showMenu.False()),
                new Box("navbar__mobile-content", [
                    new Box("navbar__mobile-links", [
                        new Link("Produkty").Class("navbar__mobile-link"),
                        new Link("Wypożyczalnia").Class("navbar__mobile-link"),
                        new Link("O nas").Class("navbar__mobile-link"),
                        new Link("Kontakt").Class("navbar__mobile-link"),
                    ]),
                ]).Visible(showMenu),
                // new Box("navbar__mobile-overlay", []).Style(`opacity: 0; display: none;`)
                // .Visible(showMenu), 
            ]), // container
            // new Checkbox(showMenu)
        );

        if (0)
            this.Html(`
                         
                                <button class="navbar__mobile-close" style="opacity: 1; transform: none;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x navbar__mobile-close-icon">
                                        <path d="M18 6 6 18"></path>
                                        <path d="m6 6 12 12"></path>
                                    </svg>
                                </button>
                                <div class="navbar__mobile-content" style="opacity: 0; transform: translateY(20px);">
                                    <div class="navbar__mobile-links">
                                        <a href="#products" class="navbar__mobile-link" style="opacity: 0; transform: translateY(10px);">Products</a>
                                        <a href="#rental" class="navbar__mobile-link" style="opacity: 0; transform: translateY(10px);">Rental</a>
                                        <a href="#store" class="navbar__mobile-link" style="opacity: 0; transform: translateY(10px);">Store</a>
                                        <a href="#support" class="navbar__mobile-link" style="opacity: 0; transform: translateY(10px);">Support</a>
                                        <a href="#contact" class="navbar__mobile-link" style="opacity: 0; transform: translateY(10px);">Contact</a>
                                    </div>
                                    <div class="navbar__mobile-actions" style="opacity: 0; transform: translateY(10px);">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-search nav-icon">
                                            <circle cx="11" cy="11" r="8"></circle>
                                            <path d="m21 21-4.3-4.3"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shopping-cart nav-icon">
                                            <circle cx="8" cy="21" r="1"></circle>
                                            <circle cx="19" cy="21" r="1"></circle>
                                            <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>`);
    }
}
