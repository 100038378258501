import { PicturePack } from "./PicturePack";


export class MultiuploadResult
{
    constructor(
        public IsSuccess = false,
        public PicturesPacks: PicturePack[] = [],
        public ErrorMessage = "") { }

    public static Success(packs: PicturePack[]): MultiuploadResult
    {
        return new MultiuploadResult(true, packs, "");
    }

    public static Failure(reason: string): MultiuploadResult
    {
        return new MultiuploadResult(false, [], reason);
    }
}
