"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatternExtractorV2 = exports.PatternExtractor = void 0;
var PatternExtractor = /** @class */ (function () {
    function PatternExtractor(pattern) {
        this.pattern = pattern;
    }
    PatternExtractor.prototype.Extract = function (url) {
        if (!url) {
            return null;
        }
        if (!this.pattern) {
            return null;
        }
        var urlParts = url.split('/');
        var patternParts = this.pattern.split('/');
        var params = {};
        if (urlParts.length !== patternParts.length) {
            return null;
        }
        for (var i = 0; i < urlParts.length; i++) {
            if (patternParts[i].startsWith(':')) {
                var paramName = patternParts[i].substring(1);
                params[paramName] = urlParts[i];
            }
            else if (urlParts[i] !== patternParts[i]) {
                return null;
            }
        }
        return params;
    };
    return PatternExtractor;
}());
exports.PatternExtractor = PatternExtractor;
/*
    TO JEST DO DUPY BO ZAWSZE COŚ ZAJDZIE WIĘC NIE NADAJE SIE DO CotentSwitchera!!!!!!!!!
*/
var PatternExtractorV2 = /** @class */ (function () {
    function PatternExtractorV2(pattern) {
        this.pattern = pattern;
    }
    PatternExtractorV2.prototype.Extract = function (input) {
        var patternSegments = this.pattern.split("/");
        var inputSegments = input.split("/");
        var params = {};
        for (var i = 0; i < patternSegments.length; i++) {
            var patternSegment = patternSegments[i];
            var inputSegment = inputSegments[i];
            if (patternSegment.startsWith(":?")) {
                var paramName = patternSegment.substring(2);
                if (paramName != "")
                    params[paramName] = inputSegment || null;
            }
            else if (patternSegment.startsWith(":")) {
                var paramName = patternSegment.substring(1);
                if (paramName != "")
                    params[paramName] = inputSegment || null;
            }
        }
        return params;
    };
    return PatternExtractorV2;
}());
exports.PatternExtractorV2 = PatternExtractorV2;
