import { RefNumber } from "@tblabs/truffle";
import { RawQuantityDiscount } from "./RawQuantityDiscount";


export class QuantityDiscount
{
    public Quantity = new RefNumber();
    public Percent = new RefNumber();

    constructor(raw: Partial<RawQuantityDiscount>)
    {
        this.Quantity.value = raw.Quantity || 0;
        this.Percent.value = raw.Percent || 0;
    }
}
