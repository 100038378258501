import { ProductCategory } from "../../../Models/Product/ProductCategory";
import { WebsiteContent } from "../../../Models/WebsiteContent/WebsiteContent";
import { Card } from "../../../Components/Product/Card";
import { CardInfo } from "../../../Components/Product/CardInfo";


export class ProductCategoryBox extends Card
{
    constructor(category: ProductCategory, content: WebsiteContent)
    {
        let priceFor = "⚠️ brak produktów";
        const firstProduct = content.ProductsByCategory(category)?.[0];
        if (firstProduct)
        {
            const rent = firstProduct.RentPrices.Items[0];
            if (!rent)
                priceFor = "⚠️ brak cennika";
            else
            {
                const price = Math.round(rent.Value.value / rent.Days.value);
                priceFor = `już od ${price}zł / dzień`;
            }
        }

        super(new CardInfo(category, priceFor));

        this.OnClick(() => window.location.hash = "category/" + category.Url.value)
    }
}
