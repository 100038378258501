import { Ref, Link, Line } from "@tblabs/truffle";
import { DeliveryFormV1, DeliveryFormV1ValueToLabel } from "../../Models/Basket/DeliveryForm";
import { ReturnFormValueToLabel } from "../../Models/Basket/ReturnForm";
import { Box } from "../Utils/Box";
import { Center } from "../Utils/Center";
import { DangerButton } from "../Utils/DangerButton";
import { InputRow } from "../Utils/InputRow";
import { Section } from "../Utils/Section";
import { CostsSummaryTable } from "./CostsSummaryTable";
import { FormInfo } from "./FormInfo";
import { OrderFormView } from "./OrderFormView";
import { DispatchTimeCalculator } from "../../Services/DispatchTimeCalculator";
import moment from "moment";


export class SummaryBox extends Box
{
    constructor(private info: FormInfo, private view: Ref<OrderFormView>)
    {
        const returnToEditBtn = new Link("Wróć do edycji").OnClick(() => view.value = OrderFormView.Form).MarginLeft(32);

        const basket = info.basket;

        const deliveryAndReturnSecrionTitle = info.deliveryForm.value === DeliveryFormV1.PackageWithoutTraining ? "🚚 Dostawa i zwrot" : "👥 Odbiór i zwrot";
        const deliveryTime = info.deliveryForm.value === DeliveryFormV1.PackageWithoutTraining ? moment(DispatchTimeCalculator.Calc(basket.RentTime.Start.value)).format("dddd DD.MM.YYYY") : basket.RentStartAsString;
        
        super(
            new Section("🧮 Podsumowanie kosztów", [
                new CostsSummaryTable(info),
            ]),
            basket.IsAnythingToRent.value && new Section(deliveryAndReturnSecrionTitle, [
                new InputRow("Dostawa", DeliveryFormV1ValueToLabel[info.deliveryForm.value], ", ", deliveryTime),
                basket.IsAnythingToRent.value && new InputRow("Zwrot", ReturnFormValueToLabel[info.returnForm.value], ", ", basket.RentEndAsString),
            ]),
            !basket.IsAnythingToRent.value && new Section("🚚 Dostawa", [
                new InputRow("Dostawa", DeliveryFormV1ValueToLabel[info.deliveryForm.value]),
            ]),
            basket.IsAnythingToRent.value && new Section("💰 Kaucja", [
                new InputRow("Wpłata", info.depositInfo.value),
                new InputRow("Zwrot", info.depositReturnInfo.value),
            ]),
            new Section("👦 Twoje dane", [
                new InputRow("Imię i nazwisko", info.Customer.Name.value),
                new InputRow("Kontakt", info.Customer.Phone.value, ", ", info.Customer.Email.value),
                new InputRow("Adres", info.Customer.Address.value),
            ]),
            new Line(),
            new Center(
                new DangerButton("Wyślij zgłoszenie").OnClick(async (btn) =>
                {
                    btn.Disabled();
                    returnToEditBtn.Hide();

                    view.value = OrderFormView.Sending;
                }),
                returnToEditBtn
            ).Margin(32)
        )
    }
}
