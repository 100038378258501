import { Span, DateInput, Button } from "@tblabs/truffle";
import { Basket } from "../../Services/Basket/Basket";
import { Center } from "../Utils/Center";



export class DatesSelector extends Center
{
    constructor(basket: Basket)
    {
        super(
            new Span("Wypożyczenie od").Margin(8),
            new DateInput(basket.DatesRange.Start).Width(140),
            new Span("do").Margin(8).MarginLeft(12),
            new DateInput(basket.DatesRange.End).Width(140),
            new Button("➖", () => basket.DatesRange.SubtractDay()).Padding(0).MarginLeft(12),
            new Button("➕", () => basket.DatesRange.AddDay()).Padding(0).MarginLeft(8)
        );

        this.BorderTop(1, "#ccc").BorderBottom(1, "#ccc")
            .MarginTopBottom(16)
            .Padding(16);

        this.Visible(basket.IsAnythingToRent, "block");
    }
}
