"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Text = void 0;
var RefComponentBase_1 = require("../../Core/RefComponentBase");
var Ref_1 = require("../../Core/Ref");
var Text = /** @class */ (function (_super) {
    __extends(Text, _super);
    function Text(ref, transform) {
        if (ref === void 0) { ref = new Ref_1.Ref(""); }
        var _this = _super.call(this, "span", ref) || this;
        _this.transform = transform;
        _this.defaultText = "";
        _this.Refresh();
        return _this;
    }
    Text.prototype.DefaultText = function (text) {
        this.defaultText = text;
        this.SetText();
        return this;
    };
    Text.prototype.SetText = function () {
        var _a, _b;
        var text = (_b = (_a = this.transform) === null || _a === void 0 ? void 0 : _a.call(this, this.ref.value)) !== null && _b !== void 0 ? _b : this.ref.value.toString();
        if (this.defaultText != "" && text === "") {
            text = this.defaultText;
        }
        this.Text(text);
    };
    Text.prototype.Refresh = function () {
        this.SetText();
        if (this.showThatChanged) {
            this.ref.HasChanged
                ? this.element.classList.add("tf-value-changed")
                : this.element.classList.remove("tf-value-changed");
        }
    };
    Text.Name = "Text";
    return Text;
}(RefComponentBase_1.RefComponentBase));
exports.Text = Text;
