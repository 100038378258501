import { BasketItemType } from "../../Models/Basket/BasketItemType";


export class BasketStorageItem
{
    constructor(
        public Type: BasketItemType,
        public ProductId: string,
        public Option: string,
        public Quantity: number) 
    { }
}
