import { Div, Span, Image, ComponentBase, TextInput, MultilineInput, Button } from "@tblabs/truffle";
 import { appendable } from "@tblabs/truffle/Core/appendable";


export class Hero extends Div
{
    constructor()
    {
        super("hero");

        this.Html(`
            <div class="hero__background">
                <div class="hero__background-image">
                    <img src="https://images.unsplash.com/photo-1557597774-9d273605dfa9?auto=format&amp;fit=crop&amp;q=80&amp;w=1200&amp;fm=webp" alt="Security Background" class="hero__image" width="1200" height="800" loading="eager">
                </div>
                <div class="hero-content">
                    <div class="hero__content">
                        <div class="hero__text">
                            <h1 class="hero__title">Wypożyczalnia Sprzętu Detektywistycznego</h1>
                            <p class="hero__subtitle">Profesjonalny sprzęt dostępny od zaraz</p>
                            <div class="hero__buttons">
                                <a href="#categories" class="button primary w-full md:w-auto">Zobacz nasz sprzęt</a>
                                <a href="#contact" class="button secondary w-full md:w-auto">Skontaktuj się z nami</a>
                            </div>
                        </div>
                    </div>
                    <div class="hero__features">
                        <div class="hero__features-grid">
                            <div class="hero__features-row">
                                <div class="hero__feature group">
                                    <div class="hero__feature-header">
                                        <div class="hero__feature-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-heart-handshake icon feature-icon">
                                                <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z"></path>
                                                <path d="M12 5 9.04 7.96a2.17 2.17 0 0 0 0 3.08v0c.82.82 2.13.85 3 .07l2.07-1.9a2.82 2.82 0 0 1 3.79 0l2.96 2.66"></path>
                                                <path d="m18 15-2-2"></path>
                                                <path d="m15 18-2-2"></path>
                                            </svg>
                                        </div>
                                        <h3 class="hero__feature-title">BEZ UMOWY BEZ DOKUMENTÓW</h3>
                                    </div>
                                    <p class="hero__feature-description">Nie potrzebujemy pisemnej umowy ani kopii dokumentów</p>
                                </div>
                                <div class="hero__feature group">
                                    <div class="hero__feature-header">
                                        <div class="hero__feature-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shield icon feature-icon">
                                                <path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z"></path>
                                            </svg>
                                        </div>
                                        <h3 class="hero__feature-title">UBEZPIECZENIE OD USZKODZEŃ</h3>
                                    </div>
                                    <p class="hero__feature-description">Nie martw się jeśli coś popsujesz - nic za to nie grozi :)</p>
                                </div>
                                <div class="hero__feature group">
                                    <div class="hero__feature-header">
                                        <div class="hero__feature-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-phone icon feature-icon">
                                                <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                                            </svg>
                                        </div>
                                        <h3 class="hero__feature-title">WSPARCIE SPECJALISTÓW</h3>
                                    </div>
                                    <p class="hero__feature-description">Zawsze służymy pomocą</p>
                                </div>
                                <div class="hero__feature group">
                                    <div class="hero__feature-header">
                                        <div class="hero__feature-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-truck icon feature-icon">
                                                <path d="M14 18V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v11a1 1 0 0 0 1 1h2"></path>
                                                <path d="M15 18H9"></path>
                                                <path d="M19 18h2a1 1 0 0 0 1-1v-3.65a1 1 0 0 0-.22-.624l-3.48-4.35A1 1 0 0 0 17.52 8H14"></path>
                                                <circle cx="17" cy="18" r="2"></circle>
                                                <circle cx="7" cy="18" r="2"></circle>
                                            </svg>
                                        </div>
                                        <h3 class="hero__feature-title">PRZESYŁKA W 24H</h3>
                                    </div>
                                    <p class="hero__feature-description">Paczki wysyłamy codziennie około godziny 13:00</p>
                                </div>
                                <div class="hero__feature group">
                                    <div class="hero__feature-header">
                                        <div class="hero__feature-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-clock icon feature-icon">
                                                <circle cx="12" cy="12" r="10"></circle>
                                                <polyline points="12 6 12 12 16 14"></polyline>
                                            </svg>
                                        </div>
                                        <h3 class="hero__feature-title">MINIMUM 3 DNI W CENIE</h3>
                                    </div>
                                    <p class="hero__feature-description">Każdy sprzęt wypożyczamy na co najmniej 3 dni. Twoje dobre przygotowanie do "zadania" jest dla nas ważne</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `)
    }
}


export class Paragraph extends ComponentBase<HTMLParagraphElement>
{
    constructor(text: string, klass?: string)
    {
        super("p");

        this.Class(klass);
        this.Text(text);
    }
}
export class H2 extends ComponentBase<HTMLHeadingElement>
{
    constructor(text: string, klass?: string)
    {
        super("h2");

        this.Class(klass);
        this.Text(text);
    }
}
export class H3 extends ComponentBase<HTMLHeadingElement>
{
    constructor(text: string, klass?: string)
    {
        super("h3");

        this.Class(klass);
        this.Text(text);
    }
}

export class AboutUs extends Div
{
    constructor()
    {
        super("about");

        this.Append(
            new Div("container").Append(
                new H2("O nas").Class("section-title ml-60 text-slate-100"),
                new Div("about__content").Append(
                    new Image("https://www.specteam.pl/img/logo_white.png").Class("about__logo"),
                    new Paragraph("Musimy przyznać – działamy w sposób nietypowy. I nie chodzi tu tylko o to, że wypożyczamy i budujemy zaawansowane, kosztowne urządzenia szpiegowskie, ale o to, że w tym wszystkim odnajdujemy prawdziwą pasję i radość.").Class("about__text"),
                    new Paragraph("Nasze podejście do rynku również odbiega od normy. Zamiast tradycyjnego biura korzystamy z sieci agentów terenowych. Rezygnujemy z umów najmu i wysokich kaucji (co nie wynika z braku zaufania – większość naszych urządzeń automatycznie dezaktywuje się po określonym czasie).").Class("about__text")
                ),
            )
        )

        // this.Html(`
        //     <section class="about">
        //         <div class="container">
        //             <h2 class="section-title ml-60 text-slate-100">O nas</h2>
        //             <div class="about__content">
        //                 <svg viewBox="-40 -30 270 330" fill="none" xmlns="http://www.w3.org/2000/svg" class="about__logo">
        //                 </svg>
        //                 <p class="about__text">Musimy przyznać – działamy w sposób nietypowy. I nie chodzi tu tylko o to, że wypożyczamy i budujemy zaawansowane, kosztowne urządzenia szpiegowskie, ale o to, że w tym wszystkim odnajdujemy prawdziwą pasję i radość.</p>
        //                 <p class="about__text">Nasze podejście do rynku również odbiega od normy. Zamiast tradycyjnego biura korzystamy z sieci agentów terenowych. Rezygnujemy z umów najmu i wysokich kaucji (co nie wynika z braku zaufania – większość naszych urządzeń automatycznie dezaktywuje się po określonym czasie).</p>
        //             </div>
        //         </div>
        //     </section>
        // `)
    }
}



export class Section extends ComponentBase<HTMLElement>
{
    constructor(klass: string)
    {
        super("section");

        this.Class(klass);
    }
}
export class Container extends ComponentBase<HTMLElement>
{
    constructor(...inside: appendable[])
    {
        super("div");

        this.Class("container");

        this.Append(...inside);
    }
}
export class FaqItem extends Div
{
    constructor(question: string, answer: string)
    {
        super("card faq__item");

        this.Append(
            new H3(question).Class("faq__question"),
            new Paragraph(answer).Class("faq__answer")
        )
    }
}

export class FaqBox extends Container
{
    constructor()
    {
        super(
            new Section("faq").Append(
                new H2("Często zadawane pytania").Class("section-title"),
                new Div("faq__list").Append(
                    new FaqItem("W czym się specjalizujemy?",
                        "Najbardziej skupiamy się na środkach do niewidocznej dla osób trzecich łączności audio-video. Opracowaliśmy w tym celu własny system Kamer GSM, które już od wielu lat cieszą się dużą popularnością. Oprócz tego wypożyczamy także wszelkiego rodzaju rejestratory dźwięku i obrazu, lokalizaotry GPS a także detektory podsłuchów."),
                    new FaqItem("Czego nie robimy?",
                        "Nie wypożyczamy rzeczy niebezpiecznych ani trudnych w obsłudze, takich jak zagłuszacze fal radiowych czy mikrofony laserowe. Nie zajmujemy się także usługami typu śledzenie osób czy zakładanie podsłuchów w domach. Każdy nasz sprzęt można z łatwością obsłużyć samodzielnie, a pomoc technika nie jest konieczna."
                    ),
                    new FaqItem("Dlaczego mamy tak niewiele produktów w ofercie?",
                        "Dobór odpowiedniego sprzętu do zadania jest trudniejszy, niżby się mogło wydawać. Dlatego uprościliśmy naszą ofertę do minimum. Każdy zestaw dobierany jest indywidualnie do potrzeb każdego klienta, a ta strona ma charakter jedynie poglądowy."
                    )
                )
            )
        )

    }
}



export class ContactBox extends Section
{
    constructor()
    {
        super("contact");
        this.Id("contact");

        this.Append(
            new Container().Append(
                new H2("Kontakt").Class("contact__title"),
                   new Div("contact__grid").Append(
                       new Div("contact__info").Append(
                        //    new H3("Get in Touch").Class("contact__info-title"),
                           new Div("contact__info-item").Append(
                            new Div().Html(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-phone icon"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>`),
                            //    new SvgIcon("phone", "lucide lucide-phone icon", "22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"),
                               new Span("+48 507 293 714")
                           ),
                        //    new Div("contact__info-item").Append(
                        //     //    new SvgIcon("mail", "lucide lucide-mail icon", "22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"),
                        //        new Span("specteam@specteam.pl")
                        //    ),
                           new Div("contact__info-item").Append(
                            new Div().Html(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-map-pin icon"><path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"></path><circle cx="12" cy="10" r="3"></circle></svg>`),
                            //    new SvgIcon("map-pin", "lucide lucide-map-pin icon", "20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"),
                               new Span("Warszawa, Polska")
                           ),
                           new Div("contact__info-item").Append(
                            new Div().Html(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-clock icon"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>`),
                            //    new SvgIcon("clock", "lucide lucide-clock icon", "12 12 10 12 6 12 14"),
                               new Span("Pon-Pt: 10:00 - 17:00\nSob: 11:00 - 15:00")
                           ),
                        //    new Div("contact__payment").Append(
                        //        new H3("Metody płatności:").Class("contact__payment-title"),
                            //    new SvgIcon("credit-card", "lucide lucide-credit-card icon", "20 14 2 5 2 10 22 10"),
                            //    new SvgIcon("bitcoin", "lucide lucide-bitcoin icon", "11.767 19.089c4.924.868 6.14-6.025 1.216-6.894m-1.216 6.894L5.86 18.047m5.908 1.042-.347 1.97m1.563-8.864c4.924.869 6.14-6.025 1.215-6.893m-1.215 6.893-3.94-.694m5.155-6.2L8.29 4.26m5.908 1.042.348-1.97M7.48 20.364l3.126-17.727")
                        //    )
                       ),
                       new Div("contact-form").Append(
                           new TextInput().Placeholder("Imię").Class("contact-form__input"),
                           new TextInput().Placeholder("Email").Class("contact-form__input"),
                           new MultilineInput().Placeholder("Wiadomość").Rows(4).Class("contact-form__input"),
                           new Button("Wyślij wiadomość").Class("button primary button--full")
                       )
                   )
               )
           )
        
    }
}


