import { Div, Select } from "@tblabs/truffle";
import { ValidableTextInput } from "../Inputs/ValidableTextInput";
import { InputRow } from "../Utils/InputRow";
import { CustomerInfo } from "./CustomerInfo";


export class CustomerInput extends Div
{
    constructor(customer: CustomerInfo)
    {
        super();

        this.Append(
            new InputRow("Imię i nazwisko", new ValidableTextInput(customer.Name)),
            new InputRow("Telefon", new ValidableTextInput(customer.Phone, (v) => v.AddRule(x => x.length <= 9, "Too short"))),
            new InputRow("Email", new ValidableTextInput(customer.Email)),
            new InputRow("Adres", new ValidableTextInput(customer.Address).Placeholder("Ulica, numer budynku, numer mieszkania, kod pocztowy i miasto")),
            new InputRow("Doświadczenie", new Select(customer.Experience, {
                "🥔 Jestem osobą totalnie atechniczną": 1,
                "🥉 Radzę sobie z technologią": 2,
                "🥈 Znam podobny sprzęt": 3,
                "🥇 Znam Wasz sprzęt": 4
            })),
        )
    }
}
