import { TicketerResult } from "./TicketerResult";
import { FormInfoToTicketConverter } from "../../Components/OrderForm/FormInfoToTicketConverter";
import { FormInfo } from "../../Components/OrderForm/FormInfo";
import { TicketSender } from "./TicketSender";
import { Ticket } from "../../Models/OrderTicket/Ticket";


export class Ticketer
{
    constructor(
        private _converter: FormInfoToTicketConverter,
        private _sender: TicketSender)
    { }

    public async Send(info: FormInfo): Promise<TicketerResult>
    {
        const ticket = this._converter.Convert(info);

        const storageResult = await this._sender.Send(ticket);

        const ticketUrl = this.GetOrdersUrl(ticket);

        return new TicketerResult(storageResult.IsSuccess, ticketUrl, storageResult.ErrorMessage);
    }

    private GetOrdersUrl(ticket: Ticket)
    {
        if (window.location.origin.includes("localhost"))
            return "http://localhost:4340/#ticket/open/" + ticket.Id;
        else if (window.location.origin.includes("new"))
            return "https://new.orders.specteam.pl/#ticket/open/" + ticket.Id;
        else
            return "https://orders.specteam.pl/#ticket/open/" + ticket.Id;
    }
}