import { Collection } from "@tblabs/truffle";
import { DeliveryForm, DeliveryFormV1 } from "../../Models/Basket/DeliveryForm";
import { FormPaymentForm } from "../../Models/OrderTicket/PaymentForm";
import { Ticket } from "../../Models/OrderTicket/Ticket";
import { RawRentTicketBasketItem, RawSaleTicketBasketItem, RawTicketBasketItem } from "../../Models/OrderTicket/RawTicketBasketItem";
import { BasketItem } from "../../Services/Basket/BasketItem";
import { BasketItemType } from "../../Models/Basket/BasketItemType";
import { v4 as uuidv4 } from 'uuid';
import { PaymentForm } from "./PaymentForm";
import { FormInfo } from "./FormInfo";
import { SaleBasketItem } from "../../Services/Basket/SaleBasketItem";
import { RentBasketItem } from "../../Services/Basket/RentBasketItem";
import { RawProductInfo } from "../../Models/OrderTicket/RawProductInfo";


export class FormInfoToTicketConverter
{
    public Convert(info: FormInfo): Ticket
    {
        const ticket = new Ticket();

        ticket.Id = uuidv4().toUpperCase();

        ticket.Meta.CreatedAt = new Date();
        ticket.Meta.CreatedBy = window.location.hostname;

        ticket.BasketList = this.MakeBasket(info.basket.Items);

        ticket.Customer.Name = info.Customer.Name.value;
        ticket.Customer.Phone = info.Customer.Phone.value;
        ticket.Customer.Email = info.Customer.Email.value;
        ticket.Customer.Address = info.Customer.Address.value;
        ticket.Customer.Experience = info.Customer.Experience.value;
        ticket.Customer.BankAccountNumber = info.Customer.DepositAccount.value;

        switch (info.deliveryForm.value)
        {
            case DeliveryFormV1.PackageWithoutTraining:
                ticket.Delivery = DeliveryForm.Package;
                ticket.WithTraining = false;
                break;
            case DeliveryFormV1.PackageWithTraining:
                ticket.Delivery = DeliveryForm.Package;
                ticket.WithTraining = true;
                break;
            case DeliveryFormV1.PersonalWithTraining:
            case DeliveryFormV1.PersonalWithoutTraining:
                ticket.Delivery = DeliveryForm.Personal;
                ticket.WithTraining = true;
                ticket.PickupPlace = info.placeWithTraining.value;
                break;
        }

        ticket.Return = info.returnForm.value;

        switch (info.paymentForm.value)
        {
            case FormPaymentForm.AllWithCash:
                ticket.DepositPaymentForm = PaymentForm.Cash;
                ticket.BasketPaymentForm = PaymentForm.Cash;
                break;
            case FormPaymentForm.DepositWithBankTransfer_ServiceWithCash:
                ticket.DepositPaymentForm = PaymentForm.BankTransfer;
                ticket.BasketPaymentForm = PaymentForm.Cash;
                break;
            case FormPaymentForm.AllWithBankTransfer:
                ticket.DepositPaymentForm = PaymentForm.BankTransfer;
                ticket.BasketPaymentForm = PaymentForm.BankTransfer;
                break;
            case FormPaymentForm.AllWithCrypto:
                ticket.DepositPaymentForm = PaymentForm.Crypto;
                ticket.BasketPaymentForm = PaymentForm.Crypto;
                break;
        }

        return ticket;
    }

    private MakeBasket(basketItems: Collection<BasketItem>): RawTicketBasketItem[]
    {
        return basketItems.Items.map(x =>
        {
            switch (x.Type)
            {
                case BasketItemType.Sale:
                    const saleItem = x as SaleBasketItem;
                    return {
                        Type: saleItem.Type,
                        ProductInfo: this.MakeProductInfo(saleItem),
                        TotalPrice: saleItem.FinalPrice.value,
                        Quantity: saleItem.Quantity.value,
                    } as RawSaleTicketBasketItem;

                case BasketItemType.Rent:
                    const rentItem = x as RentBasketItem;
                    return {
                        Type: rentItem.Type,
                        Quantity: rentItem.Quantity.value,
                        TotalPrice: rentItem.FinalPrice.value,
                        ProductInfo: this.MakeProductInfo(rentItem),
                        Option: { Id: x.OptionId, Name: x.Product.Options.GetById(x.OptionId).Label.value },
                        From: rentItem.RentStart,
                        To: rentItem.RentEnd,
                        Deposit: rentItem.Deposit,
                    } as RawRentTicketBasketItem;

                default:
                    throw new Error(`Unhandled basket item type: ${x.Type}`);
            }
        });
    }

    private MakeProductInfo(x: BasketItem): RawProductInfo
    {
        return {
            Origin: window.location.origin,
            Id: x.Product.Id,
            Name: x.Product.BasketName.value,
            Link: `#product/${x.Product.Url.value}/${x.OptionId}`,
            Category: x.Product.Category.Id.value,
            Picture: x.Product.Images.Items[0].ThumbnailUrl.value,
            Tutorial: x.Product.TutorialLink.value,
            Prices: x.Product.RentPrices.Items.map(x => `${x.Value.value}zł/${x.Days.value}dni`).join(" ; ") + " ; " + x.Product.SalePrice.value + "zł/szt",
        }
    }
}

