import { ComponentBase } from "@tblabs/truffle";



export class Path extends ComponentBase<HTMLElement>
{
    constructor(d: string)
    {
        super("path");
        this.Attribute("d", d);
    }
}
