import { RefString, RefNumber } from "@tblabs/truffle";
import { RawProductOption } from "./RawProductOption";


export class ProductOption
{
    public Url = new RefString(); // This is also ID
    public Label = new RefString();
    public Description = new RefString();
    public PriceFactor = new RefNumber(); // For example 1.25 for 25% more expensive

    constructor(raw: RawProductOption)
    {
        this.Url.value = raw.Url ?? "";
        this.Label.value = raw.Label ?? "";
        this.Description.value = raw.Description ?? "";
        this.PriceFactor.value = raw.PriceFactor ?? 1;
    }

    public GetPriceFactorText(): string
    {
        if (this.PriceFactor.value == 1)
            return "";

        return `${this.PriceFactor.value > 1 ? "+" : ""}${((this.PriceFactor.value * 100)-100)}% ceny`;
    }
}
