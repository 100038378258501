import { Div, RefBool, Link } from "@tblabs/truffle";
import { Center } from "../../Utils/Center";
import { OpinionPrinter } from "./OpinionPrinter";
import { Box } from "../../Utils/Box";
import { OpinionField } from "../../../Models/Description/Opinion/OpinionField";


export class OpinionsPrinter extends Div
{
    constructor(field: OpinionField)
    {
        super();
        this.TextAlignLeft()

        const expand = new RefBool();

        this.Append(
            ...field.Opinions
                .Take(0, 3)
                .map(x => new OpinionPrinter(x)),

            (field.Opinions.Count > 3) && new Center(
                new Link(`Pokaż wszystkie ${field.Opinions.Count}...`).Margin(32)
                    .OnClick(_ => { _.Hide(); expand.True(); })),

            new Box(
                ...field.Opinions
                    .Take(3, field.Opinions.Count)
                    .map(x => new OpinionPrinter(x)))
                .Visible(expand),
        )
    }
}
