import { Div, Span, DateInput, Text, Button } from "@tblabs/truffle";
import { DatesRange } from "../../Models/Dates/DatesRange";


export class DatesRangeInput extends Div
{
    constructor(range: DatesRange)
    {
        super();

        this.Append(
            new Span("Od").Margin(8),
            new DateInput(range.Start).Attribute("min", new Date().toISOString().split('T')[0]).Width(140),
            new Span("do").Margin(8).MarginLeft(12),
            new DateInput(range.End).Attribute("min", new Date().toISOString().split('T')[0]).Width(140),
            " = ",
            new Text(range.DaysCount, v => `${v} ${(v == 1) ? "dzień" : "dni"}`).Color("#222").DisplayInlineBlock().Width(70)
                .When(range.DaysCount, v => +v <= 0, _ => _.Color("red"), _ => _.Color("#888")),
            new Button("➖", () => range.SubtractDay()).Padding(0),
            new Button("➕", () => range.AddDay()).Padding(0)
        );
    }
}
