import { Product } from "../../Models/Product/Product";
import { ProductCategory } from "../../Models/Product/ProductCategory";


export class CardInfo
{
    public ImageUrl: string = "(image)";
    public Title: string = "(title)";
    public Description: string = "(description)";
    public Price: string = "(price)";

    constructor(product: Product | ProductCategory, price?: string)
    {
        if (product instanceof Product)
        {
            this.ImageUrl = product.Images.First()?.ThumbnailUrl.value ?? "";
            this.Title = product.Title.value;
            this.Description = product.Subtitle.value;
            this.Price = `już od ${product.PriceStartingFrom}zł / dzień`;
        }
        else if (product instanceof ProductCategory)
        {
            this.ImageUrl = product.Image.value;
            this.Title = product.Title.value;
            this.Description = product.Subtitle.value;
            this.Price = price || "";
        }
    }
}
