import { HashLink } from "../Utils/HashLink";


export class AdminEditButton extends HashLink
{
    constructor(url: string)
    {
        super("🐓 Edytuj", url);
        
        this.Class("admin-button")
    }
}
