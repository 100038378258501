import { ComponentBase, Image, Div } from "@tblabs/truffle";


export class CardFigure extends ComponentBase<HTMLElement>
{
    constructor(imageUrl: string)
    {
        super("figure");
        this.Class("product-card__image-container");

        this.Append(
            new Image(imageUrl).Class("product-card__image")
                .Attribute("loading", "lazy"),
            new Div("product-card__image-overlay")
                .Attribute("role", "presentation")
        );
    }
}
