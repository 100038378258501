import { Ref } from "@tblabs/truffle";
import { Cost } from "../../Models/Basket/Cost";
import { DeliveryFormV0, DeliveryFormV1 } from "../../Models/Basket/DeliveryForm";
import { Discount } from "../../Models/Basket/Discount";
import { ReturnableCost } from "../../Models/Basket/ReturnableCost";
import { FormPaymentForm } from "../../Models/OrderTicket/PaymentForm";
import { IBasket } from "../../Services/Basket/IBasket";


export class Costs
{
    private costs: Cost[] = [];

    constructor(
        private basket: IBasket,
        private paymentForm: Ref<FormPaymentForm>,
        private deliveryForm: Ref<DeliveryFormV1>)
    {
        this.Update();
    }

    public get ListOfCosts(): Cost[]
    {
        return this.costs;
    }

    public Update(): void
    {
        this.costs = [];

        this.basket.Items.ForEach(x =>
        {
            const optionLabel = x.OptionId
                ? " (" + x.Product.Options.List.Items.find(opt => opt.Url.value == x.OptionId)?.Label?.value + ")"
                : "";
            this.Add(new Cost(`${x.Label.value}: ${x.Product.BasketName.value}${optionLabel} (×${x.Quantity.value})`, x.FinalPrice.value));
        });

        let depositsSum = this.basket.GetDepositsSum();
        if (depositsSum)
        {
            this.Add(new ReturnableCost("Suma wszystkich kaucji", depositsSum));
        }

        if (this.deliveryForm.Is(DeliveryFormV1.PersonalWithTraining))
            this.Add(new Cost("Szkolenie z obsługi", 50));

        if ([DeliveryFormV1.PackageWithoutTraining].includes(this.deliveryForm.value))
            this.Add(new Cost("Dostawa", 1));

        if (this.paymentForm.Is(FormPaymentForm.AllWithCrypto))
        {
            const nonReturnableCosts = this.GetNonReturnableCostsSum();
            const cryptoDiscount = nonReturnableCosts * this.basket.BasketOptions.CryptoDiscount.value;
            this.Add(new Discount(`Zniżka za płatność w kryptowalucie (${this.basket.BasketOptions.CryptoDiscount.value * 100}%)`, cryptoDiscount));
        }
    }

    private Add(cost: Cost): void
    {
        this.costs.push(cost);
    }

    public GetNonReturnableCostsSum(): number
    {
        return this.costs.filter(x => x instanceof Cost).reduce((p, c) => p + c.Value, 0);
    }

    public GetReturnableCostsSum(): number
    {
        return this.costs.filter(x => x instanceof ReturnableCost).reduce((p, c) => p + c.Value, 0);
    }
}
