"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditableDateLabel = void 0;
var Text_1 = require("./Text");
var DateInput_1 = require("./DateInput");
var Div_1 = require("../Containers/Div");
var HandlersCollection_1 = require("../../Core/Tools/HandlersCollection");
var RefDate_1 = require("../../Core/RefDate");
var __1 = require("../..");
var EditableDateLabel = /** @class */ (function (_super) {
    __extends(EditableDateLabel, _super);
    function EditableDateLabel(ref, printer) {
        if (ref === void 0) { ref = new RefDate_1.RefDate(0); }
        if (printer === void 0) { printer = function (value) { return (+value == 0) ? "unset" : value.getDate() + "." + (value.getMonth() + 1) + "." + value.getFullYear(); }; }
        var _this = _super.call(this) || this;
        _this.onChange = new HandlersCollection_1.HandlersCollection();
        _this.Label = new Text_1.Text(ref, function (v) { return printer(v); })
            .CursorPointer()
            .OnClick(function () {
            _this.Label.Hide();
            _this.DateInput.Show();
            _this.OffButton.Show();
        });
        _this.DateInput = new DateInput_1.DateInput(ref).DisplayInline().WidthAuto()
            .Hide()
            .OnChange(function (date) {
            _this.DateInput.Hide();
            _this.OffButton.Hide();
            _this.Label.Show();
            _this.onChange.Call(date);
        })
            .OnBlur(function () {
            _this.DateInput.Hide();
            _this.OffButton.Hide();
            _this.Label.Show();
        });
        _this.OffButton = new __1.Button("✖").CursorPointer().Inline()
            .Hide();
        _this.OffButton.OnClick(function () {
            _this.Label.Show();
            _this.DateInput.Hide();
            _this.OffButton.Hide();
        });
        // this.DateInput.Width(85, "%")
        _this.OffButton.MarginLeft(6);
        // NIE DA SIE KLIKNĄĆ W KONTROLKE BO ŁAPIE BLURA :(
        // this.SetAttribute("tabindex", "0");
        // this.OnBlur(() =>
        // {
        //     this.DateInput.Hide();
        //     this.Label.Show();
        // });
        _this.Append(_this.Label, _this.DateInput, _this.OffButton);
        return _this;
    }
    EditableDateLabel.prototype.OnChange = function (handler) {
        this.onChange.Add(handler);
        return this;
    };
    EditableDateLabel.prototype.DateInputConfig = function (config) {
        config(this.DateInput);
        return this;
    };
    EditableDateLabel.prototype.OffButtonConfig = function (config) {
        config(this.OffButton);
        return this;
    };
    EditableDateLabel.prototype.LabelConfig = function (config) {
        config(this.Label);
        return this;
    };
    EditableDateLabel.Name = "EditableDateLabel";
    return EditableDateLabel;
}(Div_1.Div));
exports.EditableDateLabel = EditableDateLabel;
