import { HashLink } from "../Utils/HashLink";


export class AdminAddButton extends HashLink
{
    constructor(url: string)
    {
        super("🐓 Dodaj", url);
        
        this.Class("admin-button")
    }
}
