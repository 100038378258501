
export enum PaymentForm
{
    Unset = "Unset",
    Cash = "Cash",
    BankTransfer = "BankTransfer",
    CashAtDelivery = "CashAtDelivery",
    TransferAtDelivery = "TransferAtDelivery",// Przelew wykonany przez kuriera w momencie odbioru paczki
    PostalTransfer = "PostalTransfer",// Kasa nadana na poczcie w formie przelewu
    Crypto = "Crypto"
}
