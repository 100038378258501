import { MultiuploadResult } from "./MultiuploadResult";


export class OnlineStorage
{
    constructor(public url: string) { }

    public async UploadMany(files: File[], dir: string = ""): Promise<MultiuploadResult>
    {
        try
        {
            if (!files)
            {
                return MultiuploadResult.Failure("No files provided");
            }

            const formData = new FormData();

            for (let i = 0; i < files.length; i++)
            {
                formData.append('files[]', files[i]); // Tak, te [] w nazwie zmiennej są konieczne! bez tego php (mimo że odbiera przez $_FILES["files"]) nie jest w stanie odebrać wielu plików xd
            }
            formData.append('folder', dir.trim());

            const response = await fetch(this.url + "/multiupload", {
                method: 'POST',
                body: formData,
            });

            if (!response.ok)
            {
                return MultiuploadResult.Failure(`Request failed with status ${response.status}`);
            }

            const filesNames = await response.json();

            return MultiuploadResult.Success(filesNames);
        }
        catch (ex: any)
        {
            return MultiuploadResult.Failure(`Can not upload: ${ex.message}`);
        }
    }
}
