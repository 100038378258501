import { Div, CollectionPrinter, TextInput, Image, MultilineInput, Line, Link, Button, Snack } from "@tblabs/truffle";
import { InputRow } from "../../Components/Utils/InputRow";
import { Row } from "../../Components/Utils/Row";
import { AuthService } from "../../Services/Auth/AuthService";
import { WebsiteContentProvider } from "../../Services/Content/WebsiteContentProvider";
import { ProductCategory } from "../../Models/Product/ProductCategory";


export class CategoryEditPage extends Div
{
    constructor(private _content: WebsiteContentProvider, private _auth: AuthService)
    {
        super();
    }

    protected async OnAppend(): Promise<void>
    {
        if (!this._auth.IsAuthorized)
        {
            this.Append(`Brak autoryzacji`);
            return;
        }

        const content = await this._content.Get();

        this.Append(
            new Row(
                new CollectionPrinter(content.ProductCategories, x => new Div().Border(4, "#333").Margin(16).Padding(8).BorderRadius(8)
                    .Append(
                        new InputRow("Id", new TextInput(x.Id).Placeholder("Id (musi być unikalne)")),
                        new InputRow("Url", new TextInput(x.Url).Placeholder("Url (ten w pasku przeglądarki)")),
                        new InputRow("Tytuł", new TextInput(x.Title).Placeholder("")),
                        new InputRow("Pod tytułem", new TextInput(x.Subtitle).Placeholder("")),
                        new InputRow("Obrazek", new TextInput(x.Image).Placeholder("Url do miniaturki obrazka"), new Image(x.Image).Width(220)),
                        new InputRow("Opis (przy liście produktów)", new MultilineInput(x.Description).Rows(4).Placeholder("Markdown")),
                    )
                ),
                new Link("➕ Kategoria").OnClick(() => content.ProductCategories.Add(new ProductCategory({ Id: "XXX" }))),
                new Line(),
                new Button("Zapisz").Class("selected")
                    .OnClick(async () =>
                    {
                        const result = await this._content.Update(content);
                        new Snack(result.IsSuccess ? "Zmiany zapisane!" : "❌ Problem z zapisaniem zmian", result.ErrorMessage || (result.ContentSize.toFixed(2) + "kb"));
                        window.location.hash = `/`;
                    })
            )
        )
    }
}
