import { Div } from "@tblabs/truffle";
import { Loader } from "../../Components/Utils/Loader";
import { WebsiteContent } from "../../Models/WebsiteContent/WebsiteContent";
import { WebsiteContentProvider } from "../../Services/Content/WebsiteContentProvider";
import { appendable } from "@tblabs/truffle/Core/appendable";


export class WebsitePage extends Div
{
    private whenReady?: (content: WebsiteContent) => appendable;

    private loader = new Loader()
    private content = new Div()

    constructor(private __website: WebsiteContentProvider)
    {
        super();

        this.Append(
            this.loader,
            this.content
        )
    }

    protected async OnAppend(): Promise<void>
    {
        const websiteContent = await this.__website.Get();

        this.loader.RemoveSelf();

        this.content.Append(
            this.whenReady?.(websiteContent)
        )
    }

    public WhenReady(handler: (content: WebsiteContent) => appendable)
    {
        this.whenReady = handler;
        return this;
    }
}
