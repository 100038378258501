"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnorderedList = void 0;
var __1 = require("../..");
var ListItem_1 = require("./ListItem");
var UnorderedList = /** @class */ (function (_super) {
    __extends(UnorderedList, _super);
    function UnorderedList(initialItems) {
        var _this = _super.call(this, "ul") || this;
        if (initialItems)
            _this.Load(initialItems);
        return _this;
    }
    UnorderedList.prototype.Add = function (item) {
        this.Append(new ListItem_1.ListItem(item));
        return this;
    };
    UnorderedList.prototype.Load = function (items) {
        var _this = this;
        this.Clear();
        items.forEach(function (i) { return _this.Add(i); });
        return this;
    };
    UnorderedList.Name = "UnorderedList";
    return UnorderedList;
}(__1.ComponentBase));
exports.UnorderedList = UnorderedList;
