import { BasketItem } from "./BasketItem";
import { BasketStorageItem } from "./BasketStorageItem";
import { IBasketStorage } from "./IBasketStorage";


export class BasketLocalStorage implements IBasketStorage
{
    private KEY = 'basket';

    public Update(items: BasketItem[]): void
    {
        const storableItems = items.map(x => new BasketStorageItem(x.Type, x.ProductId, x.OptionId, x.Quantity.value));

        localStorage.setItem(this.KEY, JSON.stringify(storableItems));
    }

    public Get(): BasketStorageItem[]
    {
        return JSON.parse(localStorage.getItem(this.KEY) ?? "[]");
    }
}
