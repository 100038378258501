import { Div, Text } from "@tblabs/truffle";
import { Faq } from "../../../Models/Description/Faq/Faq";


export class FaqEntryView extends Div
{
    constructor(faq: Faq)
    {
        super();
        this.TextAlignLeft().Append(
            new Text(faq.Question).DisplayBlock().Color("#b37504"),
            new Text(faq.Answer).DisplayBlock().Margin(8),
        )
    }
}
