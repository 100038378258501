import { Ref, Text, TextInput, Select, Radio } from "@tblabs/truffle";
import { DeliveryFormV0, DeliveryFormV1 } from "../../Models/Basket/DeliveryForm";
import { FormPaymentForm } from "../../Models/OrderTicket/PaymentForm";
import { BasketTable } from "../BasketTable";
import { DatesRangeInput } from "../Inputs/DatesRangeInput";
import { Box } from "../Utils/Box";
import { Center } from "../Utils/Center";
import { HashLink } from "../Utils/HashLink";
import { InputRow } from "../Utils/InputRow";
import { PrimaryButton } from "../Utils/PrimaryButton";
import { Section } from "../Utils/Section";
import { CustomerInput } from "./CustomerInput";
import { FormInfo } from "./FormInfo";
import { OrderFormView } from "./OrderFormView";
import { ReturnForm } from "../../Models/Basket/ReturnForm";


export class FormBox extends Box
{
    constructor(info: FormInfo, view: Ref<OrderFormView>)
    {
        const basket = info.basket;

        super(
            new Section("🗓️ Okres wypożyczenia", [
                new DatesRangeInput(basket.DatesRange)
            ]).Visible(basket.IsAnythingToRent, "block"),

            new Section("🛒 Twoje zamówienie", [
                new BasketTable(basket),
                new Box(
                    new Text(basket.TotalPrice, v => `Łącznie ${v} zł`).MarginRight(148)
                ).TextAlignRight().MarginTop(8),
                new Center(
                    new HashLink("➕ Dodaj produkty").Color("#1e87f0")
                ).MarginTop(20),
            ]),

            new Section(basket.IsAnythingToRent.value ? "🚚 Odbiór i zwrot" : "🚚 Dostawa", [
                new InputRow("Odbiór",
                    new Radio(info.deliveryForm).Class("radio")
                        .Add(DeliveryFormV1.PackageWithTraining, "Kurier Pocztex pobranie (+1zł)")
                        .Add(DeliveryFormV1.PersonalWithTraining, ["Odbiór osobisty ze szkoleniem (+51zł)",
                            new Select(info.placeWithTraining, ["Warszawa/Wola"]).MarginLeft(12).WidthAuto()
                        ], x => { x.Label.MarginLeft(0); })
                        .Add(DeliveryFormV1.PersonalWithoutTraining, ["Odbiór osobisty bez szkolenia (+1zł)",
                            new Select(info.placeWithoutTraining, ["Warszawa/Wola", "Warszawa/Mokotów"]).MarginLeft(12).WidthAuto()
                        ], x => { x.Label.MarginLeft(0); })
                ),
                new InputRow("Zwrot",
                    new Radio(info.returnForm).Class("radio")
                        .Add(ReturnForm.Package, "Paczkomat (na koszt nadawcy)")
                        .Add(ReturnForm.Personal, "Zwrot osobisty (bez kosztów)")
                ).Visible(basket.IsAnythingToRent, "flex")
            ]),

            new Section("💰 Płatność", [
                new Radio(info.paymentForm).Class("radio")
                    .Add(FormPaymentForm.AllWithCash, "Całość płatna gotówką przy odbiorze", x => x.Enable(info.allowCashPayment))
                    .Add(FormPaymentForm.DepositWithBankTransfer_ServiceWithCash, "Kaucja płatna przelewem, reszta gotówką przy odbiorze")
                    .Add(FormPaymentForm.AllWithBankTransfer, "Całość z góry przelewem", x => x.Disabled())
                    .Add(FormPaymentForm.AllWithCrypto, "Całość Bitcoinem z góry", x => x.Disabled())
                    .Visible(basket.IsAnythingToRent, "flex"),
                new Radio(info.paymentForm).Class("radio")
                    .Add(FormPaymentForm.AllWithCash, "Gotówka przy odbiorze")
                    .Add(FormPaymentForm.AllWithBankTransfer, "Przelew")
                    .Add(FormPaymentForm.AllWithCrypto, `Bitcoin (${basket.BasketOptions.CryptoDiscount.value*100}% rabatu)`, x => x.Disabled())
                    .VisibleWhen(basket.IsAnythingToRent, v=>!v, "flex"),
                new InputRow("Zwrot kaucji na konto", new TextInput(info.Customer.DepositAccount).Placeholder("Twój numer konta do zwrotu kaucji"))
                    .Visible(info.showCustomerDepositAccountInput, "flex")
            ]),

            new Section("🤵 Twoje dane", [
                new CustomerInput(info.Customer),
            ]),
/*
            TODO:
            new Section("🎈 Rabaty", [
                new InputRow("Kod rabatowy", new TextInput().Placeholder("Wpisz jeśli jakiś posiadasz")),
            ]),
*/
            new Center(
                new PrimaryButton("Przejdź do podsumowania")
                    .EnableWhen([basket.Items.CountRef, info.deliveryForm, info.paymentForm],
                        (count, delivery, payment) => +count > 0
                            && delivery != DeliveryFormV0.Unset
                            && payment != FormPaymentForm.Unset)
                    .OnClick(async () =>
                    {
                        view.value = OrderFormView.Summary;
                    })
            ).Margin(32)
        );
    }
}