import { Button, Div, Line, Link, RefBool, Span } from "@tblabs/truffle";
import { Basket } from "../../Services/Basket/Basket";
import { BasketTable } from "../BasketTable";
import { Center } from "../Utils/Center";
import { DatesSelector } from "./DatesSelector";


export class MiniBasketBox extends Div
{
    constructor(basket: Basket, showMiniBasket: RefBool)
    {
        super("MiniBasketBox");
      
        this.Visible(showMiniBasket)
           
        this.Append(
                new Span("🛒 Koszyk").Italic().FontSize(24),
                new Link("✖️").Style(`position: absolute; top: 4px; right: 4px; cursor: pointer;`)
                    .OnClick(() => showMiniBasket.False()),
                new DatesSelector(basket),
                new BasketTable(basket),
                new Line(),
                new Center(
                    new Button("Zobacz koszyk").Class("selected").Margin(8)
                        .EnableWhen(basket.Items.CountRef, c => +c > 0)
                        .OnClick(() =>
                        {
                            showMiniBasket.False();
                            window.location.hash = "basket";
                        })
                )
            )
    }
}
