import { Collection, RefNumber, RefBool } from "@tblabs/truffle";
import { BasketItemType } from "../../Models/Basket/BasketItemType";
import { DatesRange } from "../../Models/Dates/DatesRange";
import { BasketItem } from "./BasketItem";


export class BasketOptions
{
    public CryptoDiscount = new RefNumber(0.1);
    public GeneralPriceFactor = new RefNumber(1);
}


export interface IBasket
{
    RentTime: DatesRange;
    Items: Collection<BasketItem>;
    BasketOptions: BasketOptions;
    TotalPrice: RefNumber;
    TotalDeposits: RefNumber;
    IsAnythingToRent: RefBool;
    DatesRange: DatesRange;
    GetItem(index: number): BasketItem;
    GetDepositsSum(): number;
    Clear(): void;
    CanAdd(type: BasketItemType, productId: string, option: string): boolean;
    Add(type: BasketItemType, productId: string, option: string, quantity?: number): boolean;
    RentStartAsString: string;
    RentEndAsString: string;
}
