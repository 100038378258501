"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Span = void 0;
var ComponentBase_1 = require("../../Core/ComponentBase");
var Span = /** @class */ (function (_super) {
    __extends(Span, _super);
    function Span(text) {
        var _this = _super.call(this, "span") || this;
        if (text)
            _this.Text(text);
        return _this;
    }
    Span.Name = "Span";
    return Span;
}(ComponentBase_1.ComponentBase));
exports.Span = Span;
// export class SpanV2 extends RefComponentBase<primitive, HTMLSpanElement>
// {
//     public static Name = "Span"; 
//     constructor(
//         private valueOrRef?: primitive | Ref<primitive>, 
//         private transform?: (value: primitive) => string)
//     {
//         if (valueOrRef instanceof Ref)
//             super("span", valueOrRef);
//         else
//             super("span");
//         this.Refresh();
//     } 
//     public Refresh()
//     {
//         if (typeof this.valueOrRef == "string")
//             this.Text(this.valueOrRef)
//         else if (typeof this.valueOrRef == "number")
//             this.Text(this.valueOrRef.toString())
//         else if (this.valueOrRef instanceof Ref)
//             this.Text(this.transform?.(this.valueOrRef.value) ?? this.valueOrRef.value.toString());
//     }
// }
