import { RefString, Select, SelectOption, MultilineInput, Div } from "@tblabs/truffle";
import { Product } from "../../../Models/Product/Product";


export class ProductOptionSelector extends Div
{
    constructor(optionId: RefString, product: Product)
    {
        super();

        const description = new RefString();

        optionId.OnChange(id =>
        {
            const opt = product.Options.List.Items.find(x => x.Url.value == id);

            description.value = opt?.Description?.value ?? "";

            const url = `#product/${product.Url.value}/${id}`;
            history.replaceState(null, "", url);
        }, true);

        const productOptions = product.Options.List.Items.map(x => new SelectOption(x.Label.value, x.Url.value));

        if (optionId.value == "")
        {
            optionId.value = productOptions?.[0]?.Value ?? "";
        }

        product.Options.List.Count
            && this.Append(
                new Select(optionId, productOptions).MarginBottom(4).Border(2, "orange").MarginBottom(0),
                new MultilineInput(description).Readonly().Rows(2).FontSize(13).Italic().MarginTop(0).MarginBottom(16).Color("orange")
                    .VisibleWhen(description, v => v.length > 0)
            )
    }
}
